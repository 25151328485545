<template>
  <form
      v-if="modalData"
      @submit.prevent="onPrimaryAction"
      @keydown.esc="onSecondaryAction">
    <div class="modal-container">
      <div v-if="modalData.title" class="modal-container__title">{{ modalData.title }}</div>
      <div class="modal-container__content--container">
        <overlay-scrollbars
            :options="{
                scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
                overflowBehavior: { x: 'hidden'}
               }"
        >
          <div class="modal-container__content--list">
            <div v-if="modalData.description" class="modal-container__description">{{ modalData.description }}</div>
            <div v-if="$slots.default" class="modal-container__slot">
              <slot></slot>
            </div>
          </div>
        </overlay-scrollbars>
      </div>
      <div v-if="type === 'input'" class="modal-container__input">
        <text-form-element
            ref="focusMe"
            :element="inputElement"
            :focus="focus"
            :passed-value="inputValue"
            @modified="inputValue = $event"
            @focus="focus = $event"
        ></text-form-element>
      </div>
      <div v-if="type === 'dropdown' && modalData.dropdownElements" class="modal-container__input">
        <DropdownFormElement
            :element="{
                    options: modalData.dropdownElements
                  }"
            :passed-value="modalData.dropdownElements[0]"
            @modified="inputValue = $event"
        />
      </div>
      <div class="modal-container__footer">
        <Button
            v-if="modalData.secondaryButtonText"
            :text="modalData.secondaryButtonText"
            variant="secondary"
            name="secondary"
            :toggle-text="false"
            type="button"
            @click="onSecondaryAction"
        />
        <Button
            v-if="modalData.primaryButtonText"
            :text="modalData.primaryButtonText"
            variant="primary"
            name="primary"
            :toggle-text="false"
            type="submit"
        />
      </div>
    </div>
  </form>
</template>

<script>
import Button from "../../components/styled/Button.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import {OverlayScrollbarsComponent} from "overlayscrollbars-vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";

export default {
  components: {
    DropdownFormElement,
    TextFormElement,
    Button,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    modalData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    initialInputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: this.modalData.dropdownElements?.[0] || this.initialInputValue,
      focus: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.focusMe?.$el?.querySelector('input')?.focus();
    });
  },
  computed: {
    inputElement() {
      return {
        id: 'info_overlay_input',
        fieldType: 'text',
        fieldLabel: this.modalData?.inputPlaceholder || '',
        hideLabel: !this.modalData?.inputPlaceholder };
    },
  },
  methods: {
    onPrimaryAction() {
      if (this.modalData?.primaryAction) {
        if (this.type === "input" || this.type === "dropdown") {
          this.modalData.primaryAction(this.inputValue);
        } else {
          this.modalData.primaryAction();
        }
      }
    },
    onSecondaryAction() {
      if (this.modalData?.secondaryAction) {
        this.modalData.secondaryAction();
      }
    }
  },
};
</script>

<style scoped>
.modal-container__input {
  margin-top: 20px;
}

.modal-container__content--container {
  max-height: 80vh;
}

.modal-container__content--list {
  max-height: 70vh;
}

.modal-container__description {
  color: var(--foreground-color);
}

.modal-container__slot {
  margin-top: 20px;
}
</style>

