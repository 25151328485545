import axios from "axios";

export default function updateSession(view, sort, order, size, mode) {
    let path = '/api/updateSession';
    let params = {};
    if (!!view) {
        params.view = view;
    }
    if (!!sort) {
        params.sessionSort = sort;
    }
    if (!!size) {
        params.sessionSize = size;
    }
    if (!!order) {
        params.sessionOrder = order;
    }
    if (!!mode) {
        params.sessionMode = mode;
    }
    params = {...params};
    axios
        .get(path, {
            params: params
        })
        .catch(error => {
            console.log(error);
        })
}
