<template>
  <div class="monitoring_form__form__settings__schedule">
    <div>{{ $t('genios.monitoring.recipients') }}</div>
    <div v-for="(email, index) in emails">
      <div class="monitoring_form__form__settings__schedule__emails">
        <text-form-element
            :passed-value="email"
            :element="emailElements[index]"
            :focus="focused"
            :hide-label="true"
            v-on:focus="$emit('focus', $event)"
            v-on:modified="emails[index] = ($event === null ? '' : $event)"
        />
        <Button
            type="button"
            variant="icon"
            v-if="emails.length > 1"
            @click="emails.splice(index, 1)"
            icon="trash-outline"
        />
        <Button
            type="button"
            variant="icon"
            @click="emails.splice(index + 1, 0, '')"
            icon="add-outline"
        />
      </div>
      <div class="enter__error" v-if="error && error['email_' + index]">
        <span>{{ error['email_' + index] }}</span>
      </div>
    </div>
    <div class="enter__error" v-if="error && error.emails">
      <span>{{ error.emails }}</span>
    </div>

    <!-------------Emails Group Part------------>

    <email-group-action :checked-emails-group="checkedEmailsGroup" :shared-state="sharedState"/>

    <!-------------Emails Group Part------------>

    <!--suppress XmlInvalidId -->
    <label :for="'ad_fo_el_' + intervalElement.id" class="space_above">{{
        $t('genios.monitoring.intervalText')
      }}</label>
    <dropdown-form-element
        :passed-value="selectedSendMode"
        :element="intervalElement"
        v-on:focus="$emit('focus', $event)"
        @modified="sendMode = $event.value"
    />
    <template v-if="sendMode === 'DAY_MASK'">
      <div class="flex">
        <div class="space_above">{{ $t('genios.monitoring.daySelectText') }}</div>
      </div>
      <div class="monitoring_form__form__settings__schedule__day_selector">
        <button v-for="day in days"
                type="button"
                class="monitoring_form__form__settings__schedule__day_selector__button"
                :class="daySelected[day] ? 'monitoring_form__form__settings__schedule__day_selector__button-active' : ''"
                @click="daySelected[day] = !daySelected[day]"
        >
          <span>{{ $t('genios.monitoring.dayButton.' + day) }}</span>
        </button>
      </div>
      <dropdown-form-element
          :key="dailyHourElement.id"
          :passed-value="selectedDailyHourElement"
          :element="dailyHourElement"
          v-on:focus="$emit('focus', $event)"
          @modified="dailyHour = $event.value"
      />
    </template>
    <div v-if="sendMode === 'DAILY_MULTIPLE'">
      <div class="monitoring_form__form__settings__schedule__daily_multiple_text">
        {{ $t('genios.monitoring.dailyMultipleText') }}
      </div>
    </div>
    <div v-if="sendMode === 'MONTHLY'">
      <div class="flex">
        <!--suppress XmlInvalidId -->
        <label :for="'ad_fo_el_' + dayOfMonthElement.id" class="space_above">{{
            $t('genios.monitoring.dayOfMonthText')
          }}</label>
      </div>
      <dropdown-form-element
          :key="dayOfMonthElement.id"
          :passed-value="selectedDayOfMonthElement"
          :element="dayOfMonthElement"
          v-on:focus="$emit('focus', $event)"
          @modified="dayOfMonth = $event.value"
      />
    </div>
    <div class="monitoring_form__form__settings__schedule__layout__header space_above">
      <!--suppress XmlInvalidId -->
      <label :for="'ad_fo_el_' + layoutElement.id">{{ $t('genios.monitoring.layoutText') }}</label>
      <button class="monitoring_form__layout_preview__info_button" @mouseover="showLayoutPreview()"
              @mouseout="hideLayoutPreview()">
        <ion-icon name="information-circle-outline"/>
        <div class="monitoring_form__layout_preview__arrow">
          <img src="/figures/monitoring/pointer.png" class="fit_image" v-if="showPreview" alt="">
        </div>
      </button>
    </div>
    <layout-preview :showLayoutPreview="showPreview"/>
    <dropdown-form-element
        :key="layoutElement.id"
        :passed-value="selectedLayoutElement"
        :element="layoutElement"
        :focus="focused"
        v-on:focus="$emit('focus', $event)"
        @modified="layout = $event.value"
    />
    <label class="monitoring_form__form__settings__checkbox">
      <ion-icon name="checkbox-outline" v-if="showProximity"/>
      <ion-icon name="square-outline" v-else/>
      <span>{{ $t('genios.monitoring.showProximityText') }}</span>
      <input
          id="monitoring_form__show_proximity"
          type="checkbox"
          v-model="showProximity"
      >
    </label>
    <label class="monitoring_form__form__settings__checkbox">
      <ion-icon name="checkbox-outline" v-if="decompound"/>
      <ion-icon name="square-outline" v-else/>
      <span>{{ $t('genios.monitoring.decompoundText') }}</span>
      <input
          ref="monitoring_form__decompound"
          id="monitoring_form__decompound"
          type="checkbox"
          v-model="decompound"
      >
    </label>
    <div class="space_above flex">
      <div class="space_above flex">{{ $t('genios.monitoring.hitsText') }}</div>
    </div>
    <div class="monitoring_form__form__settings__schedule__hits">
      <label class="monitoring_form__form__settings__radio">
        <ion-icon name="radio-button-on-outline" v-if="noOfHits === 10"/>
        <ion-icon name="radio-button-off-outline" v-else/>
        <span>10</span>
        <input
            id="monitoring_form__hits_ten"
            name="monitoring_form__hits"
            type="radio"
            :value="10"
            v-model="noOfHits"
        >
      </label>
      <label class="monitoring_form__form__settings__radio">
        <ion-icon name="radio-button-on-outline" v-if="noOfHits === 100"/>
        <ion-icon name="radio-button-off-outline" v-else/>
        <span>100</span>
        <input
            id="monitoring_form__hits_hundred"
            name="monitoring_form__hits"
            type="radio"
            :value="100"
            v-model="noOfHits"
        >
      </label>
    </div>
  </div>
</template>

<script>
import i18n from "../plugins/Translations.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import LayoutPreview from "./LayoutPreview.vue";
import Button from "../styled/Button.vue";
import Error from "../styled/Error.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import {emailsComputedGroupMixin, emailsGroupMixin} from "../../../functions/mixins/emailsGroup";
import EmailGroupAction from "./EmailGroupAction.vue";

export default {
  name: "ScheduleSubForm",
  components: {
    EmailGroupAction,
    CheckboxFormElement,
    Error,
    Button,
    LayoutPreview,
    DropdownFormElement,
    TextFormElement
  },
  mixins: [emailsGroupMixin, emailsComputedGroupMixin],
  props: {
    focused: [String, Number],
    schedule: Object,
    error: [Object, Boolean],
    sharedState: {
      type: Object,
      required: true
    },
    emailsGroupList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      intervalElement: {
        id: 'monitoring_form__interval_type',
        field: 'sendMode',
        fieldType: 'DROPDOWN',
        dataType: 'TEXT',
        options: [
          {
            text: i18n.t('genios.monitoring.interval.onceADay'),
            value: 'DAY_MASK'
          },
          {
            text: i18n.t('genios.monitoring.interval.severalTimesADay'),
            value: 'DAILY_MULTIPLE'
          },
          {
            text: i18n.t('genios.monitoring.interval.monthly'),
            value: 'MONTHLY'
          }
        ]
      },
      dailyHourElement: {
        id: 'monitoring_form__daily_hour',
        field: 'dailyHour',
        fieldType: 'DROPDOWN',
        dataType: 'TEXT',
        options: Array.from(Array(25).keys()).slice(1).map(i => {
          return {
            text: i18n.t('genios.monitoring.dailyHour.prefix') + ' ' + ("0" + (i - 1)).slice(-2) + ':00 ' + i18n.t('genios.monitoring.dailyHour.postfix'),
            value: i - 1
          }
        })
      },
      dayOfMonthElement: {
        id: 'monitoring_form__day_of_month',
        field: 'dayOfMonth',
        fieldType: 'DROPDOWN',
        dataType: 'TEXT',
        options: Array.from(Array(29).keys()).slice(1).map(i => {
          return {
            text: i18n.t('genios.monitoring.dayOfMonth.prefix') + ' ' + i + '.',
            value: i
          }
        })
      },
      layoutElement: {
        id: 'monitoring_form__layout',
        field: 'layout',
        fieldType: 'DROPDOWN',
        dataType: 'TEXT',
        options: [
          {
            text: i18n.t('genios.monitoring.layout.newsletter'),
            value: 'NEWSLETTER'
          },
          {
            text: i18n.t('genios.monitoring.layout.list'),
            value: 'LIST'
          }
        ]
      },
      showPreview: false,
      emails: this.schedule.emails,
      sendMode: this.schedule.sendMode,
      daySelected: this.mask2Days(this.schedule.dayMask),
      dailyHour: this.schedule.dailyHour,
      dayOfMonth: this.schedule.dayOfMonth,
      layout: this.schedule.layout,
      showProximity: this.schedule.showProximity,
      decompound: this.schedule.decompound,
      noOfHits: this.schedule.noOfHits
    }
  },
  computed: {
    emailElements: function () {
      return this.schedule.emails.map((email, index) => {
        return {
          id: 'monitoring_form__email_' + index,
          field: 'email_' + index,
          fieldType: 'TEXT',
          fieldLabel: i18n.t("genios.monitoring.label.email")
        }
      });
    },
    selectedSendMode: function () {
      if (this.schedule.sendMode) {
        return this.intervalElement.options.find((item) => item.value === this.schedule.sendMode);
      }
      return this.intervalElement.options[0];
    },
    selectedDailyHourElement: function () {
      if (this.schedule.dailyHour) {
        return this.dailyHourElement.options.find((item) => item.value === this.schedule.dailyHour);
      }
      return this.dailyHourElement.options[14];
    },
    selectedDayOfMonthElement: function () {
      if (this.schedule.dayOfMonth) {
        return this.dayOfMonthElement.options.find((item) => item.value === this.schedule.dayOfMonth);
      }
      return this.dayOfMonthElement.options[0];
    },
    selectedLayoutElement: function () {
      if (this.schedule.layout) {
        return this.layoutElement.options.find((item) => item.value === this.schedule.layout);
      }
      return this.layoutElement.options[0];
    },
    days: function () {
      return Object.keys(this.daySelected);
    },
    dayMask: function () {
      let dayMask = 0;
      dayMask += this.daySelected.monday ? 1 : 0;
      dayMask += this.daySelected.tuesday ? 2 : 0;
      dayMask += this.daySelected.wednesday ? 4 : 0;
      dayMask += this.daySelected.thursday ? 8 : 0;
      dayMask += this.daySelected.friday ? 16 : 0;
      dayMask += this.daySelected.saturday ? 32 : 0;
      dayMask += this.daySelected.sunday ? 64 : 0;
      return dayMask;
    },
    newSchedule: function () {
      return {
        emails: this.emails,
        sendMode: this.sendMode,
        dayMask: this.dayMask,
        dailyHour: this.dailyHour,
        dayOfMonth: this.dayOfMonth,
        layout: this.layout,
        showProximity: this.showProximity,
        decompound: this.decompound,
        noOfHits: this.noOfHits
      }
    }
  },
  watch: {
    newSchedule: function (value) {
      this.$emit("update-schedule", value);
    }
  },
  methods: {
    mask2Days: function (dayMask = this.schedule.dayMask) {
      return {
        monday: !!(dayMask & 0b0000001),
        tuesday: !!(dayMask & 0b0000010),
        wednesday: !!(dayMask & 0b0000100),
        thursday: !!(dayMask & 0b0001000),
        friday: !!(dayMask & 0b0010000),
        saturday: !!(dayMask & 0b0100000),
        sunday: !!(dayMask & 0b1000000)
      }
    },
    showLayoutPreview: function () {
      this.showPreview = true;
    },
    hideLayoutPreview: function () {
      this.showPreview = false;
    },
  }
}
</script>

<style scoped>
.dropdown {
  width: 60%;
}

.monitoring_form__form__settings__schedule__layout__header {
  justify-content: flex-start;
}

.monitoring_form__layout_preview__info_button {
  margin-left: 5px;
}
</style>
