import { render, staticRenderFns } from "./SelectedView.vue?vue&type=template&id=52b44fae&scoped=true"
import script from "./SelectedView.vue?vue&type=script&lang=js"
export * from "./SelectedView.vue?vue&type=script&lang=js"
import style0 from "./SelectedView.vue?vue&type=style&index=0&id=52b44fae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52b44fae",
  null
  
)

export default component.exports