import {isUndefined} from "lodash";

export const paymentForms = {
	ERROR_USER_NOT_AUTHORIZED: 'ERROR_USER_NOT_AUTHORIZED',
	ERROR_INVALID_STEP: 'ERROR_INVALID_STEP', //dev
	ERROR_UNKNOWN_ERROR: 'ERROR_UNKNOWN_ERROR',//dev
	ERROR_MISSING_PARAM: 'ERROR_MISSING_PARAM',//dev
	MESSAGE_IP_LOCKED: 'MESSAGE_IP_LOCKED', //limitUsage popup
	MESSAGE_INVALID_IP_DOORWAY: 'MESSAGE_INVALID_IP_DOORWAY', //redirect index
	MESSAGE_DELIVERY_LOCKED_LIMIT: 'MESSAGE_DELIVERY_LOCKED_LIMIT', //limitUsage popup
	REQUEST_CAPTCHA: 'REQUEST_CAPTCHA', //limitUsage caPCHA
	ERROR_INVALID_CAPTCHA: 'ERROR_INVALID_CAPTCHA',
	REQUEST_SEARCH_PURPOSE: 'REQUEST_SEARCH_PURPOSE',
	ERROR_COUNTRY_FORBIDDEN: 'ERROR_COUNTRY_FORBIDDEN',
	ERROR_SEARCH_PURPOSE: 'ERROR_SEARCH_PURPOSE',
	ERROR_SEARCH_PURPOSE_1: 'ERROR_SEARCH_PURPOSE_1',
	ERROR_SEARCH_PURPOSE_2: 'ERROR_SEARCH_PURPOSE_2',
	REQUEST_SEARCH_REASON: 'REQUEST_SEARCH_REASON',
	ERROR_INVALID_SEARCH_REASON: 'ERROR_INVALID_SEARCH_REASON',
	REQUEST_TRIAL_USER_UPGRADE: 'REQUEST_TRIAL_USER_UPGRADE', // not use
	ERROR_INVALID_PRIMARY_EMAIL: 'ERROR_INVALID_PRIMARY_EMAIL',
	ERROR_INVALID_ALTERNATIVE_EMAIL: 'ERROR_INVALID_ALTERNATIVE_EMAIL',
	REQUEST_BILL_APPROVAL: 'REQUEST_BILL_APPROVAL', //noCoupopnWarning
	REQUEST_CONFIRMATION_PAID_APPROVAL: 'REQUEST_CONFIRMATION_PAID_APPROVAL',
	ERROR_CONFIRM_PAID_VALIDATION: 'ERROR_CONFIRM_PAID_VALIDATION',
	SHOW_PAYMENT_MAIN_FORM: 'SHOW_PAYMENT_MAIN_FORM',
	SHOW_LIGHT_REGISTRATION_FORM: 'SHOW_LIGHT_REGISTRATION_FORM',
	ERROR_PAYPAL_TRANSACTION_ID: 'ERROR_PAYPAL_TRANSACTION_ID',
	REDIRECT_PAYPAL_URL: 'REDIRECT_PAYPAL_URL',
	SHOW_SOFORT_PREVIEW: 'SHOW_SOFORT_PREVIEW',
	ERROR_SOFORT_PAYMENT_VALIDATION: 'ERROR_SOFORT_PAYMENT_VALIDATION',
	REDIRECT_SOFORT_URL: 'REDIRECT_SOFORT_URL',
	ERROR_CREDIT_CARD_PAYMENT_VALIDATION: 'ERROR_CREDIT_CARD_PAYMENT_VALIDATION',
	INVALID_CREDIT_CARD: 'INVALID_CREDIT_CARD',
	SHOW_CREDIT_CARD_PREVIEW: 'SHOW_CREDIT_CARD_PREVIEW',
	REDIRECT_CREDIT_CARD_URL: 'REDIRECT_CREDIT_CARD_URL',
	ERROR_CREDIT_CARD_AUTHORIZATION: 'ERROR_CREDIT_CARD_AUTHORIZATION',
	ERROR_REMOTE_AGENCY: 'ERROR_REMOTE_AGENCY',
	ORDER_REQUEST: 'ORDER_REQUEST',
	ERROR_IS_NON_INTERACTIVE_MODE: 'ERROR_IS_NON_INTERACTIVE_MODE',
	ERROR_REGISTRATION_USER_EXISTS: 'ERROR_REGISTRATION_USER_EXISTS',
	ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED: 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED',
	ERROR_REGISTRATION_VALIDATION: 'ERROR_REGISTRATION_VALIDATION',
	REDIRECT_DOWNLOAD_ATTACHMENT: 'REDIRECT_DOWNLOAD_ATTACHMENT',
	REDIRECT_DOWNLOAD_REMOTE_AGENCIES: 'REDIRECT_DOWNLOAD_REMOTE_AGENCIES',
    REDIRECT_DOWNLOAD_E_PAPER: 'REDIRECT_DOWNLOAD_E_PAPER',
    ERROR_UNZER: 'ERROR_UNZER',
	SHOW_WEB_USER_LOGIN: 'SHOW_WEB_USER_LOGIN'
}

// not forget to change array in orderingProcess.mainForm.steps => de.json and en.json
// The order of steps should be in the exact order of payment process steps
export const paymentSteps = [
    {name: 'choice', code: 'SHOW_LIGHT_REGISTRATION_FORM'},
    {name: 'paymentType', code: 'SHOW_PAYMENT_MAIN_FORM'},
    {name: 'info', code: 'TRANSACTION_IN_PROGRESS'}
]
// {name: 'creditCard', code: 'CREDIT_CARD_FORM'},


//modes for buying at the beginning and in the end of payment process
export const PaymentMode = {
    fullDocument: "full",
    previewDocument: "preview",
    attachment: "attachment",
    multiDocuments: 'multi',
    remoteAgency: "remote",
    ePaper: "ePaper",
    queryPopup: "queryPopup",
    video: "video"
}

export const isCountryWithVatId = function (ccode) {
    if (isUndefined(ccode) || !ccode.trim()) {
        return false
    }
    return ["AT", "BE", "BG", "CY", "CZ", "DK", "EE", "ES", "FI", "FR", "GR", "HR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SE", "SK", "SI"].includes(ccode);
}

export const isCountryGermany = function (ccode) {
    if (isUndefined(ccode) || !ccode.trim()) {
        return false
    }
    return "DE" === ccode;
}

export const countryVatPrefix = function (ccode) {
    switch (ccode) {
        case "GR":
            return "EL";
        default:
            return ccode;
    }
};

export const vatIdRegex = /(?:^[A-Z]{2}(\d{8,12})$)|(?:^[A-Z]{2}([A-Z]{0,2}\d{7,10}[A-Z]{0,2})$)|(?:^(IE)\s*(\d[A-Z0-9\\+\\*]\d{5}[A-Z])$)|(?:^(NL)[A-Z\+\-\d]{12}$)|(?:^(XI)[GDHA]{2}\d\d\d$)/;
