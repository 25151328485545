<template>
  <div class="mein-genios-template">
    <div class="mein-genios-template__header">
      <div class="mein-genios-template__header--title" v-if="pageTitle">
        {{pageTitle}}
      </div>
      <div v-if="pageInfo" class="mein-genios-template__header__info">{{pageInfo}}</div>
    </div>
    <div class="mein-genios-template__body">
      <div class="mein-genios-template__variants">
        <slot name="variants"></slot>
      </div>
      <div class="mein-genios-template__filters">
        <slot name="filters"></slot>
      </div>
    </div>
    <div class="mein-genios-template__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

import eventBus from "../../../eventBus";
import scrollMixin from "../../../functions/stylization/scrollMixin";

export default {
  name: "MeinGeniosTemplate.vue",
  mixins: [scrollMixin],
  props: {
    pageTitle: String,
    pageInfo: String,
  },
  mounted() {
    eventBus.$on('scrollEvent', this.onScroll);
  },
  beforeDestroy() {
    eventBus.$off('scrollEvent', this.onScroll);
  },
  methods: {
    onScroll(scrollPosition) {
      const { screenWidth: width } = window.sharedState;
      const bookmarkBox = document.querySelector(".bookmark-box");
      const bookmarkBoxHeight = bookmarkBox ? bookmarkBox.offsetHeight : 0;
      const baseCondition = width > 1000 ? 70 : 150;
      const topCondition = baseCondition + bookmarkBoxHeight;

      const stickyElement = document.querySelector(".header-action-bar");
      if (stickyElement) {
        stickyElement.classList.toggle('sticky-active', scrollPosition >= topCondition);
      }
    },
  }
}
</script>

<style scoped>
</style>
