<template>
  <Button
      variant="primary"
      name="mode"
      border="none"
      :icon="mode === 'light' ? 'moon-outline' : 'sunny-outline'"
      aria-label="mode"
      :text="`${mode === 'light' ? 'Dark': 'Light'} Mode`"
      :text-style="400"
      :toggle-text="false"
      type="button"
      position="left"
      @click="toggleMode(mode === 'light' ? 'dark': 'light');click()"
  />
</template>

<script>

import Button from "../styled/Button.vue";
import updateSession from "../../../functions/updateSession";

export default {
  name: 'Mode',
  components: {Button},
  data: () => ({
    mode: window.matchMedia("(prefers-color-scheme: dark)").matches && 'dark' || localStorage.getItem('mode') || 'light',
    target: document.querySelector('body')
  }),
  mounted() {
    //observing system setting for changing modes
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");

    const isMode = localStorage.getItem('mode')
    if (!isMode || isMode === 'null') {
      this.handleModeChange(mediaQueryList)
      mediaQueryList.addEventListener('change', this.handleModeChange);
    } else {
      this.toggleMode(isMode);
    }
  },
  methods: {
    toggleMode(value) {
      const targetClass = this.target.classList
      localStorage.setItem('mode', value)
      this.mode = value
      this.mode === 'dark' ? targetClass.add('dark-theme') : targetClass.remove('dark-theme')
      updateSession(null, null, null, null, value)
    },
    click() {
      this.$emit('click')
    },
    handleModeChange(mql) {
      if (mql.matches) {
        this.toggleMode('dark');
      } else {
        this.toggleMode('light');
      }
    }
  }
}

</script>
