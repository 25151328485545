<template>
  <div v-if="showFilterList">
    <div class="request-text"><strong>{{ $t('genios.requestTextLabel') }}:</strong> {{ requestText }}</div>

    <div
        v-if="hasActiveFilters || advancedSearchFields.length > 0"
        class="searchResult__active_filters"
        :class="sharedState.page === 'browse' ? 'source-result-filters': ''"
    >
      <template v-for="(value, name) in activeFilters">
        <active-filter-tag
            @filter-removed="removeFilter"
            v-for="filter in value"
            :key="name+':'+filter"
            :element="filter"
            :type="name"
        />
      </template>
      <active-filter-tag
          v-for="filterField in advancedSearchFields"
          :key="filterField.type + ':' + filterField.element"
          @filter-removed="removeAdvancedFilter"
          :element="filterField.element"
          :type="filterField.type"
      />
    </div>
  </div>
</template>

<script>
import ActiveFilterTag from "./ActiveFilterTag.vue";
import {toggleSearchResultActiveFilter} from "../../../functions/components/filters";
import {replaceSearchResult} from "../../../functions/replacing";
import eventBus from "../../../eventBus";

export default {
  name: "ActiveFilters",
  components: {ActiveFilterTag},
  props: {
    sharedState: Object,
    replaceResultOnChange: Boolean,
    searchMask: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      requestText: window.sharedState.queryString,
      showFilterList: true
    }
  },
  methods: {
    substringAfter(string, splitter) {
      return string.split(splitter).splice(1).join(splitter);
    },
    removeFilter: function (event) {
      if (this.sharedState.page !== "browse") {
        toggleSearchResultActiveFilter(event.element, event.type, this.sharedState, this.replaceResultOnChange);
      } else {
        this.sharedState.browseActiveFilters[event.type] = this.sharedState.browseActiveFilters[event.type].filter(item => item !== event.element)
        eventBus.$emit("update-loader", true)
        eventBus.$emit("update-active-filter", {type: event.type, value: event.element})
      }
      this.$emit("filter-removed", event); //bubble-up
    },
    removeAdvancedFilter: function (event) {
      this.sharedState.advancedSearchParams = this.sharedState.advancedSearchParams
          .filter(obj => !(obj.includes('-' + event.type) &&
              (event.element instanceof Object && (obj.includes(event.element.from) || obj.includes(event.element.to)))))
          .filter(obj => !(obj.includes('-' + event.type) && (obj.includes(event.element))));
      this.sharedState.offset = 0;
      if (this.replaceResultOnChange) {
        replaceSearchResult();
      }
    },
    isRangeFilter: function (fromToIndexOrValue) {
      return fromToIndexOrValue.toLowerCase() === "from" || fromToIndexOrValue.toLowerCase() === "to";
    },
    addRangeFilterToMap: function (map, type, fromToIndexOrValue, paramSplit) {
      if (map.has(type)) {
        let existing = map.get(type);
        existing[fromToIndexOrValue] = paramSplit[paramSplit.length - 1];
        map.set(type, existing);
      } else {
        let newObj = {};
        newObj[fromToIndexOrValue] = paramSplit[paramSplit.length - 1];
        map.set(type, newObj);
      }
      return null;
    }
  },
  computed: {
    advancedSearchFields: function () {
      let map = new Map();
      let fields = this.sharedState.advancedSearchParams.map(param => {
        if (typeof param === 'string') {
          const paramSplit = param.split('_');
          const type = this.substringAfter(paramSplit[0], '-');
          const fromToIndexOrValue = paramSplit[1];
          if (this.isRangeFilter(fromToIndexOrValue)) {
            return this.addRangeFilterToMap(map, type, fromToIndexOrValue, paramSplit);
          }
          return {type: type, element: paramSplit[paramSplit.length - 1]}
        }
      }).filter(param => param !== null);
      map.forEach((value, key) => {
        fields.push({type: key, element: value});
      });
      if (this.searchMaskLabels?.length) {
        fields = fields.filter(field => this.searchMaskLabels.includes(field.type))
      }
      return fields;
    },
    hasActiveFilters() {
      for (const key in this.activeFilters) {
        if (this.activeFilters[key].length > 0) return true
      }
      return false
    },
    activeFilters() {
      if (this.sharedState.page === "browse")
        return this.sharedState.browseActiveFilters
      return this.sharedState.activeFilters
    },
    searchMaskLabels() {
      return this.searchMask?.elements?.map(element => element.fieldLabel)
    }
  },
  watch: {
    requestText(newValue) {
      this.requestText = newValue
    }
  },
}
</script>
