<template>
  <div class="header-layout--selected-container">
    <overlay-scrollbars
        :options="{
            scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
            overflowBehavior: { x: 'hidden'}
           }"
    >
      <div class="header-layout--selected-list">
        <div class="header-layout--selected-line"
             v-for="(item, $index) in selectedObjects"
             :key="$index">
          <div class="header-layout--selected-title" v-if="item.title">{{ item.title }}</div>
          <Button
              type="button"
              name="close"
              @click="$emit('remove-item', {item, index: $index})"
              icon="close-outline"
              variant="icon"
          />
        </div>
      </div>
    </overlay-scrollbars>
    <hr class="line"/>
    <Button
        name="remove-all"
        :toggle-text="false"
        :text="$t('genios.removeAll')"
        @click="$emit('remove-all')"
        variant="secondary"
        border="none"
        class="remove-all-btn"
        decoration="underline"
    />
  </div>
</template>

<script>
import Button from "../../../styled/Button.vue";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';

export default {
  name: "SelectedView",
  components: {
    Button,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  emits: ['remove-item', 'remove-all'],
  props: {
    selectedArticles: {
      type: Array,
      required: false,
      default: []
    }
  },
  computed: {
    selectedObjects() {
      return this.selectedArticles.map(item => ({
        title: item.documentTitle || item.title || '-',
        id: item.documentId || item.id
      }))
    }
  },
}
</script>

<style scoped>
.remove-all-btn {
  padding-bottom: 18px;
}
.remove-all-btn:hover {
  background: transparent;
}
</style>