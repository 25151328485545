<template>
  <InfoOverlayComponent
      v-if="show"
      :modal-data="modalData"
      @primary-action="primaryAction"
      @secondary-action="secondaryAction"
      id="confirmation-modal"
  />
</template>

<script>
import InfoOverlayComponent from "../common/InfoOverlayComponent.vue";
import {clearActivationApi, resendConfirmationEmailApi} from "../../../functions/fetching";

export default {
  name: "WebUserConfirmation",
  components: {
    InfoOverlayComponent,
  },
  props: {
    sharedState: Object
  },
  computed: {
    show() {
      return this.sharedState?.activation?.empty === false;
    },
    status() {
      return this.sharedState?.activation?.activationState || '';
    },
    modalData() {
      return this.getModalData(this.status);
    }
  },
  beforeDestroy() {
    clearActivationApi();
    window.sharedState.activation = null;
  },
  methods: {
    async primaryAction() {
      if (this.status === 'EXPIRED') {
        await resendConfirmationEmailApi();
      }
      await clearActivationApi();
      window.sharedState.activation = null;
    },
    async secondaryAction() {
      await clearActivationApi();
      window.sharedState.activation = null;
    },
    getModalData(status) {
      const camelCaseStatus = status
          .toLowerCase()
          .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());

      return {
        title: this.$t(`webUserConfirmation.${camelCaseStatus}.title`),
        description: this.$t(`webUserConfirmation.${camelCaseStatus}.description`),
        primaryButtonText: this.$t(`webUserConfirmation.${camelCaseStatus}.primaryButton`),
      };
    },
  },
}
</script>

<style scoped>
</style>
