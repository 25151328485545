<template>
  <div :id="'wbt_'+documentId" class="watchlist-toggle-container">
    <div class="tooltip tooltip-icon" v-if="hasPermission('bookmark')">
      <Button
          type="button"
          @click="checkBookmarkAction"
          :icon="isSelected?'bookmark':'bookmark-outline'"
          aria-label="bookmark"
          variant="icon"
          v-bind:class="(isSelected)?'bookmarked' : 'only-on-hover'"
      />
      <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.bookmark")}}</div>
    </div>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";
import {hasPermission} from "../../../functions/utils/permission_utils";
import {convertToZulu} from "../../../functions/utils/date_utils";
import {openWatchlistAddOverlay, openWatchlistDeleteOverlay} from "../../../functions/opens";

export default {
  name: "WatchlistBookmarkToggleComponent",
  components: {
    Button
  },
  props: {
    sharedState: Object,
    documentId: String,
    documentTitle: String,
    documentItemDate: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showDeleteDialog: false,
    };
  },
  computed: {
    isSelected() {
      if (this.sharedState.watchlistDocumentIds === null || typeof (this.sharedState.watchlistDocumentIds) === 'undefined') {
        return false;
      } else {
        return this.sharedState.watchlistDocumentIds.filter(item => item).findIndex(item => item.documentId === this.documentId) !== -1;
      }
    },
    deleteBookmarkId() {
      return this.sharedState.watchlistDocumentIds.filter(item => item).filter(item => item.documentId === this.documentId).map(item => item.bookmarkId);
    },
    documentToAdd() {
      const docToAdd = {
        bookmarkedItem: this.documentId,
      }
      if (this.documentItemDate) {
        return {
          ...docToAdd,
          itemDate:convertToZulu(this.documentItemDate)
        }
      }
      return docToAdd;
    }
  },
  methods: {
    checkBookmarkAction() {
      if (!this.readOnly) {
        if (this.isSelected) {
          openWatchlistDeleteOverlay(this.deleteBookmarkId, this.documentTitle, false, false);
        } else {
          openWatchlistAddOverlay([this.documentToAdd], false);
        }
      }
    },
    hasPermission: function (name) {
      return hasPermission(name)
    }

  }
}
</script>

<style scoped>
.watchlist-toggle-container {
  position: relative;
}
</style>
