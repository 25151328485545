<template>
  <div class="searchResult__active_filters__filter_tag"
       :class="{'searchResult__active_filters__filter_tag--simple': !removable}"
  >
    <div class="searchResult__active_filters__filter_tag__display">
      <div class="searchResult__active_filters__filter_tag__display__category">
        {{ displayType }}
      </div>
      <div class="searchResult__active_filters__filter_tag__display__text"
           :class="{'searchResult__active_filters__filter_tag__display__text--simple': !removable}"
      >
        {{ displayText }}
      </div>
    </div>

    <button type="button" aria-label="close" @click="remove()" v-if="erasable">
      <ion-icon name="close-outline"></ion-icon>
    </button>
    <span v-else>&nbsp;&nbsp;</span>
  </div>

</template>

<script>
import i18n from "../plugins/Translations.vue";
import getActiveNavigationName from "../../../functions/utils/get_active_navigation_name";

export default {
  name: "ActiveFilterTag",
  props: {
    element: [String, Object, Array],
    type: String,
    removable: {
      type: Boolean,
      default: true,
      required: false
    },
    navigationLevel: {
      type: String,
      required: false
    },
  },
  methods: {
    remove: function () {
      this.$emit('filter-removed', {element: this.element, type: this.type});
    },
    resolveMessage: function (key, fallback) {
      const displayText = i18n.t(key);
      if (displayText === key) {
        return fallback;
      }
      return displayText;
    }
  },
  computed: {
    displayType: function () {
      if (this.element === "true") {
        return this.resolveMessage('genios.includes', '');
      }
      if (this.type === 'section') {
        return this.$t('genios.mediaType');
      }
      if (this.type === 'category') {
        if (this.navigationLevel) {
          return this.navigationLevel;
        }
        return getActiveNavigationName();
      }
      return this.resolveMessage('genios.' + this.type.toLowerCase(), this.type);
    },
    displayText: function () {
      if (this.element === "true") {
        return this.type;
      }

      if (typeof this.element === "string") {
        return this.element;
      }

      if (this.element.string !== undefined) {
        return this.element.string;
      }

      if (this.element.from !== undefined && this.element.to !== undefined) {
        return this.element.from + " - " + this.element.to;
      } else {
        if (this.element.from !== undefined) {
          return "> " + this.element.from;
        }
        if (this.element.to !== undefined) {
          return "< " + this.element.to;
        }
      }
    },
    erasable :function () {
      if (this.element.erasable !== undefined) {
        return this.element.erasable;
      } else {
        return this.removable;
      }
    }
  }
}
</script>

<style scoped>

</style>
