import {deleteDocumentHistory, fetchDocumentHistoryFromApi, updateDocumentPrices} from "../../fetching";
import {SortingBy, SortingOrder} from "../../consts";


const initialState = {
    documentHistory: [],
    selectedDocumentHistory: [],
    pagesForRequestedDocuments: 0,
    sortSize: 10,
    sortBy: SortingBy.BY_DATE_CREATED,
    sortOrder: SortingOrder.DESCENDING,
    currentPage: 1,
    loading: true,
    error: false,
    errorMessage: "",
    ...window.sessionVariables?.['vuexState']?.documentHistoryModule || {}
}

const documentHistoryModule =  {
    namespaced: true,
    state: {...initialState},
    getters: {
        getDocumentHistory: state => state.documentHistory,
        getSelectedDocumentHistory: state => state.selectedDocumentHistory,
        getPagesForRequestedDocuments: state => state.pagesForRequestedDocuments,
        getSortSize: state => state.sortSize,
        getSortBy: state => state.sortBy,
        getSortOrder: state => state.sortOrder,
        getCurrentPage: state => state.currentPage,
        getLoading: state => state.loading,
    },
    mutations: {
        setDocumentHistory(state, documentHistory) {
            state.documentHistory = documentHistory;
        },
        setSelectedDocumentHistory(state, documentHistory) {
            state.selectedDocumentHistory = documentHistory.filter((bookmark, index, self) =>
                index === self.findIndex(b => b.bookmarkId === bookmark.bookmarkId)
            );
        },
        setPagesForRequestedDocuments(state, noOfDocuments) {
            state.pagesForRequestedDocuments = noOfDocuments;
        },
        setSortSize(state, payload) {
            state.sortSize = payload;
        },
        setSortBy(state, payload) {
            state.sortBy = payload;
        },
        setSortOrder(state, payload) {
            state.sortOrder = payload;
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload;
        },
        resetState(state) {
            Object.assign(state, initialState);
        }
    },
    actions: {
        async fetchDocumentHistory({ commit, getters }) {
            commit('setLoading', true);
            const params = {
                offset: getters.getCurrentPage - 1,
                size: getters.getSortSize,
                sort: getters.getSortBy,
                order: getters.getSortOrder,
            };
            try {
                commit('setDocumentHistory', []);
                const documentHistoryList = await fetchDocumentHistoryFromApi(params);
                commit('setPagesForRequestedDocuments', documentHistoryList?.totalPages || 0);
                const items = documentHistoryList?.content || [];

                const allDocumentIds = items.map(item => item.bookmarkedItem);
                const docIds = [...allDocumentIds];
                const documents = await updateDocumentPrices(docIds, items);
                commit('setDocumentHistory', documents || []);
            } catch (e) {
                commit('setPagesForRequestedDocuments', 0);
                commit('setDocumentHistory', []);
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteDocumentHistory({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await deleteDocumentHistory(ids);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        }
    },
}

export default documentHistoryModule;
