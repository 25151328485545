<template>
  <div id="monitoring_form" class="enter monitoring_form">
    <div class="enter__header">
      {{ $t('genios.monitoring.headerText') }}
    </div>
    <form @submit.prevent="submitForm" class="monitoring_form__form">
      <div class="monitoring_form__form__top_line">
        <text-form-element
            :element="nameElement"
            :focus="focused"
            :passed-value="name"
            :error="!!(error && error.name)"
            @focus="focused = $event"
            @modified="name = $event"
        />
        <Button
            name="monitoring_settings"
            variant="secondary"
            type="button"
            @click="stateSettingsOpen(!settingsOpen)"
            class="monitoring_form__form__top_line__settings_button"
        >
          <ion-icon src="/icons/custom/chevron-up-outline.svg" v-if="settingsOpen"/>
          <ion-icon name="settings-outline" v-else></ion-icon>
        </Button>
      </div>
      <div
          class="enter__error"
          v-if="error && error.name"
      >{{ error.name }}
      </div>
      <div class="monitoring_form__form__summary" v-show="!settingsOpen">
        {{ summaryText }}
      </div>
      <div class="monitoring_form__form__settings" v-show="settingsOpen">
        <div>
          <div class="monitoring_form__form__settings__search_title">{{ $t('genios.monitoring.search') }}</div>
          <text-form-element
              prefix-icon-name="search-outline"
              :element="searchTextElement"
              :focus="focused"
              :passed-value="query"
              :hide-label="true"
              @focus="focused = $event"
              @modified="query = $event"
          />
        </div>
        <div
            v-if="effectiveState.availableFilters"
            class="searchResult__active_filters"
        >
          <template v-for="(value, name) in effectiveState.availableFilters">
            <active-filter-tag
                v-for="filter in value"
                :key="name+':'+filter"
                :element="filter"
                :type="name"
                :navigation-level="getNavigation(name)"
                :removable="filter.erasable"
                @filter-removed="filterRemoved($event)"
            />
          </template>
        </div>

        <CheckboxFormElement :element="scheduleActiveCheckboxObj"
                             :passed-value="schedule.active"
                             :bold-on-selection="true"
                             @modified="schedule.active = $event"/>

        <schedule-sub-form
            v-show="schedule.active"
            :focused="focused"
            :schedule="schedule"
            :error="error"
            :shared-state="sharedState"
            :emails-group-list="emailsGroupList"
            @focus="focused = $event"
            @update-schedule="schedule = {...schedule, ...$event}"
        />
      </div>
      <div
          class="enter__error enter__error-space_above"
          v-if="error && error.general"
      >
        <span>{{ error.general }}</span>
      </div>
      <div class="enter__form__submit_container monitoring_form__form__submit_container">
        <Button
            name="close-modal"
            :text="$t('genios.monitoring.cancelText')"
            type="button"
            @click="closeModal"
            variant="secondary"
            :toggle-text="false"
        />
        <Button
            :text="$t('genios.monitoring.submitText')"
            variant="primary"
            v-if="schedule.active"
            id="submit1"
            name="submit1"
            :toggle-text="false"
        />
        <Button
            :text="$t('genios.monitoring.submitTextSaveOnly')"
            variant="primary"
            v-else
            id="submit2"
            name="submit2"
            :toggle-text="false"
        />
      </div>
    </form>
  </div>
</template>

<script>
import i18n from "../plugins/Translations.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import {closeTransparentPopupModal} from "../../../functions/closing";
import ActiveFilters from "../Filters/ActiveFilters.vue";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import ScheduleSubForm from "./ScheduleSubForm.vue";
import Button from "../styled/Button.vue";
import {fetchSearchMask} from "../../../functions/fetching";
import {emailsDataGroupMixin, emailsGroupMixin} from "../../../functions/mixins/emailsGroup";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import ActiveFilterTag from "../Filters/ActiveFilterTag.vue";

export default {
  name: "SearchProfileMonitoringForm",
  components: {ActiveFilterTag, Button, ScheduleSubForm, ActiveFilters, TextFormElement, CheckboxFormElement},
  props: {
    savedSearchProfile: {
      type: Object,
      default: () => {}
    },
    createProfile: false,
    sharedState: Object
  },
  mixins: [emailsGroupMixin, emailsDataGroupMixin],
  data() {
    return {
      name: this.savedSearchProfile.name || "",
      navigationLevel: this.savedSearchProfile.analysis?.navigation || "",
      nameElement: {
        id: 'monitoring_form__name',
        field: 'name',
        fieldType: 'TEXT',
        fieldLabel: i18n.t("genios.monitoring.label.name")
      },
      searchTextElement: {
        id: 'monitoring_form__search_text',
        field: 'query',
        fieldType: 'TEXT',
        fieldLabel: i18n.t('genios.monitoring.searchText')
      },
      focused: 1,
      query: this.savedSearchProfile.query,
      schedule: {
        active: this.savedSearchProfile.active ?? true,
        emails: this.savedSearchProfile.emails || [],
        sendMode: this.savedSearchProfile.sendMode || "DAY_MASK",
        dayMask: this.savedSearchProfile.dayMask || 31,
        dailyHour: this.savedSearchProfile.dailyHour || (new Date).getHours(),
        dayOfMonth: this.savedSearchProfile.dayOfMonth ?? 1,
        showProximity: this.savedSearchProfile.showProximity ?? true,
        decompound: this.savedSearchProfile.decompound ?? true,
        layout: this.savedSearchProfile.layout || "NEWSLETTER",
        noOfHits: this.savedSearchProfile.profileId ? this.savedSearchProfile.noOfHits : 10,
        emailsGroupList: this.savedSearchProfile.emailGroupsIds || []
      },
      scheduleActiveCheckboxObj: {
        id: "scheduleActive",
        fieldLabel: i18n.t('genios.monitoring.toggleText')
      },
      settingsOpen: this.savedSearchProfile.active,
      error: null,
      effectiveState: this.modifyActiveFiltersForMonitoring(),
      filterTypeToTermMap: {
        source: "QUELLENNAME",
        person: "Z2",
        topic: "Z4",
        industry: "Z5",
        company: "Z1",
        region: "Z3",
        author: "Z6",
        instructor: "Z7"
      },
      searchMask: {}
    };
  },
  computed: {
    summaryText: function () {
      return i18n.t("genios.monitoring.summary.prefix") +
          " \"" +
          this.query +
          "\" " +
          i18n.t('genios.monitoring.summary.conjunction') +
          " " +
          (this.schedule ? (" " + this.scheduleText) : '');
    },
    dayMaskText: function () {
      switch (this.schedule.dayMask) {
        case 127:
          return i18n.t('genios.monitoring.summary.schedule.dayMask.everyDay');
        case 31:
          return i18n.t('genios.monitoring.summary.schedule.dayMask.weekDays');
      }
      return i18n.t('genios.monitoring.summary.schedule.dayMask.selectedDays')
    },
    scheduleText: function () {
      switch (this.schedule.sendMode) {
        case 'DAY_MASK' :
          return i18n.t('genios.monitoring.summary.schedule.dayMask.prefix') +
              ' ' +
              this.dayMaskText +
              ' ' +
              i18n.t('genios.monitoring.summary.schedule.dayMask.conjunction') +
              ' ' +
              this.schedule.dailyHour +
              i18n.t('genios.monitoring.summary.schedule.dayMask.postfix');
        case 'DAILY_MULTIPLE' :
          return i18n.t('genios.monitoring.summary.schedule.dailyMultiple');
      }
      return i18n.t('genios.monitoring.summary.schedule.monthly.prefix')
          + ' '
          + this.schedule.dayOfMonth
          + i18n.t('genios.monitoring.summary.schedule.monthly.postfix');
    },
    advancedLabelToSearchMaskElement: function () {
      let map = {};
      if (this.searchMask.elements && this.searchMask.elements.length) {
        this.searchMask.elements.forEach(element => {
          if (element.fieldLabel) {
            map[element.fieldLabel] = element;
          }
        });
      }
      return map;
    },
    advancedSearchQueries: async function () {
      let queryForField = {};
      const url = this.savedSearchProfile.analysis?.url || '' ;
      const params = new URLSearchParams(url.split('?')[1]);
      const advancedSearchParams = params.getAll('advanced');
      advancedSearchParams.forEach(param => {
        const paramKeyValue = param.split('_');
        const paramKey = paramKeyValue[0].split('-')[1];
        const paramValue = paramKeyValue[1];
        const advancedLabelToSearchMaskElement = this.advancedLabelToSearchMaskElement;
        const searchMaskElement = advancedLabelToSearchMaskElement[paramKey];
        if (searchMaskElement) {
          switch (searchMaskElement.fieldType) {
            case 'TEXT':
              queryForField[searchMaskElement.field] = paramValue;
              break;
            case 'CHECKBOX':
              const checkBoxValue = searchMaskElement.fieldEsValue || searchMaskElement.fieldValue;
              queryForField[searchMaskElement.field] = checkBoxValue;
              break;
            case 'DROPDOWN':
              const optionDD = searchMaskElement.options.find(option => (option.text === paramValue || option.value === paramValue));
              const searchValue = optionDD ? optionDD.esValue || optionDD.value || paramValue : paramValue;
              queryForField[searchMaskElement.field] = searchValue;
              break;
            case 'CHECKBOX_GROUP':
              const searchText = paramKeyValue[paramKeyValue.length - 1];
              const optionCBP = searchMaskElement.options.find(option => option.text === searchText);
              const searchValueCBG = optionCBP.esValue || optionCBP.value || searchText

              const newQuery = searchValueCBG;
              const existingQuery = queryForField[searchMaskElement.field];
              const queryString = (!existingQuery) ? newQuery : existingQuery + " OR " + newQuery;
              queryForField[searchMaskElement.field] = queryString;
              break;
          }
        }
      });
      return queryForField;
    },
    queryForField: function () {
      let queryForField = {...this.advancedSearchQueries};
      if (this.effectiveState.availableFilters) {
        Object.keys(this.effectiveState.availableFilters).forEach(filterType => {
          const esFieldName = this.filterTypeToTermMap[filterType];
          const values = this.effectiveState.availableFilters[filterType];
          if (esFieldName) {
            if (filterType === 'source') {
              values.forEach(value => {
                if (queryForField[esFieldName]) {
                  queryForField[esFieldName] = queryForField[esFieldName] + ' OR "' + value + '"';
                } else {
                  queryForField[esFieldName] = '"' + value + '"';
                }
              });
            } else if (!['section', 'category', 'date'].includes(filterType)) {
              values.forEach(value => {
                if (queryForField[esFieldName]) {
                  queryForField[esFieldName] = queryForField[esFieldName] + ' AND "' + value + '"';
                } else {
                  queryForField[esFieldName] = '"' + value + '"';
                }
              });
            }
          }
        });
      }
      return queryForField;
    },
    rangesForFields: function () {
      let queryForField = {};
      const url = this.savedSearchProfile.analysis?.url || '' ;
      const params = new URLSearchParams(url.split('?')[1]);
      const advancedSearchParams = params.getAll('advanced');
      advancedSearchParams.forEach(param => {
        const paramKeyValue = param.split('_');
        const paramKey = paramKeyValue[0].split('-')[1];
        const advancedLabelToSearchMaskElement = this.advancedLabelToSearchMaskElement;
        const searchMaskElement = advancedLabelToSearchMaskElement[paramKey];
        if (searchMaskElement) {
          if (searchMaskElement.fieldType === 'RANGE') {
            const fields = searchMaskElement.field.split(",");
            if (paramKeyValue.length > 2) {
              const isFrom = "from" === paramKeyValue[1];
              let value = paramKeyValue[paramKeyValue.length - 1];
              if (searchMaskElement.dataType === 'DATE') {
                const dateValue = moment(value, 'D.M.Y');
                value = dateValue.format('YYYY-MM-DD');
              }
              fields.forEach(field => {
                if (!queryForField[field]) {
                  queryForField[field] = {};
                }
                if (isFrom) {
                  queryForField[field].from = value;
                } else {
                  queryForField[field].to = value;
                }
              });
            }
          }
        }
      });
      return queryForField;
    },
    navigationTree: function () {
      return this.sharedState.navigationTree;
    },
    nameToTextIdMap: function () {
      let map = {};
      const navigation = this.sharedState.urlSecondLevel || this.effectiveState.analysis?.navigation;
      const addToMap = (element) => {
        map[element.name] = element.textId;
        if (element.nextLevel && navigation && (element.name === navigation)) {
          element.nextLevel.forEach(next => addToMap(next));
        }
      }
      this.navigationTree.forEach(element => addToMap(element));
      return map;
    },
    searchTags: function () {
      let searchTags = [this.navigationTree[0].textId]
      if (this.effectiveState.availableFilters?.category?.length) {
        searchTags = this.effectiveState.availableFilters.category.map(category => {
          return this.nameToTextIdMap[category];
        });
      } else {
        const navigation = this.sharedState.urlSecondLevel || this.effectiveState.analysis?.navigation;
        if (navigation) {
          searchTags = [this.navigationTree.find(el => el.name === navigation).textId]
        }
      }
      return searchTags;
    },
    searchProfile: function () {
      return {
        name: this.name,
        active: !!(this.schedule.active),
        query: this.query,
        queryForField: this.queryForField,
        rangesForFields: this.rangesForFields,
        searchTags: this.savedSearchProfile.searchTags || this.searchTags,
        hasTrendReport: false,
        hasSocialMedia: false,
        ...this.schedule
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      const url = this.savedSearchProfile.analysis.url;
      const params = new URLSearchParams(url.split('?')[1]);
      const advancedSearchParams = params.getAll('advanced');
      if (advancedSearchParams.length > 0) {
        const match = url.match(/\/searchResult(.*?)\?/);
        const result = match ? match[1] : null;
        await this.loadSearchMask(result);
      }
      this.useDefaultEmail();
    })
  },
  methods: {
    stateSettingsOpen(value) {
      this.settingsOpen = value
    },
    modifyActiveFiltersForMonitoring: function () {
      let currentStateCopy = _.cloneDeep(this.savedSearchProfile);
      currentStateCopy.availableFilters = {section: [], ...currentStateCopy.availableFilters};
      //don't need section if we have category and don't need either if we have source
      if (currentStateCopy && currentStateCopy.availableFilters) {
        if (currentStateCopy.availableFilters.source && currentStateCopy.availableFilters.source.length) {
          currentStateCopy.availableFilters.category = [];
        } else if (!(currentStateCopy.availableFilters.category && currentStateCopy.availableFilters.category.length)) {
          if (currentStateCopy.analysis?.url) {
            const {topLevel, secondLevel} = this.extractNavigation(currentStateCopy.analysis.url);
            if (secondLevel) {
              currentStateCopy.availableFilters.section.push(secondLevel);
            } else if (topLevel) {
              currentStateCopy.availableFilters.section.push({
                string: topLevel,
                erasable: false
              });
            }
          }
        }
        delete currentStateCopy.availableFilters.date; //no date for repeatable queries
      }
      return currentStateCopy;
    },
    filterRemoved: function (event) {
      let activeFilters = this.effectiveState.availableFilters;

      let tempActiveFilters = this.effectiveState.availableFilters[event.type];
      if (tempActiveFilters === undefined) {
        tempActiveFilters = [event.element];
      } else if (tempActiveFilters.includes(event.element)) {
        const index = tempActiveFilters.indexOf(event.element);
        if (index > -1) {
          tempActiveFilters.splice(index, 1);
        }
      } else {
        tempActiveFilters.push(event.element);
      }
      activeFilters[event.type] = [...tempActiveFilters];

      if (event.type === "source") {
        if ((Array.isArray(activeFilters.source) && !activeFilters.source.length)) {
          activeFilters.category = [...this.sharedState.activeFilters.category];
        }
      }
      if (event.type === "source" || event.type === "category") {
        if ((Array.isArray(activeFilters.category || []) && !activeFilters.category.length) ||
            (Array.isArray(activeFilters.source || []) && !activeFilters.source.length)) {
          const {topLevel, secondLevel} = this.extractNavigation(this.effectiveState.analysis.url);
          if (secondLevel) {
            activeFilters.section = [secondLevel];
          } else if (topLevel) {
            activeFilters.section = [{string: topLevel, erasable: false}];
          }
        }
      } else if (event.type === "section") {
        const {topLevel} = this.extractNavigation(this.effectiveState.analysis.url);
        if (topLevel) {
          activeFilters.section = [{
            string: topLevel,
            erasable: false
          }];
        }
      }
    },
    emailIsValid: function (email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    },
    validate: function () {
      this.error = {};
      if (!this.name) {
        this.error.name = i18n.t('genios.monitoring.error.nameMissing')
      }
      if (this.searchProfile.active) {
        if (this.searchProfile.emails.filter(email => email.length).length < 1) {
          this.error.emails = i18n.t('genios.monitoring.error.emailsEmpty');
          this.settingsOpen = true;
        }
        this.searchProfile.emails.forEach((email, index) => {
          if (email.length && !this.emailIsValid(email)) {
            this.error['email_' + index] = i18n.t('genios.monitoring.error.emailNotValid');
            this.settingsOpen = true;
          }
        });
      }
      if (Object.keys(this.error).length < 1) {
        this.error = false;
      } else {
        this.error.general = i18n.t('genios.monitoring.error.invalidInput');
      }
    },
    submitForm: async function () {
      this.validate();
      let newSearchProfile = {...this.searchProfile};
      if (this.savedSearchProfile.profileId) {
        newSearchProfile.profileId = this.savedSearchProfile.profileId;
      }
      if (newSearchProfile.emails) {
        newSearchProfile.emails = [...newSearchProfile.emails.filter(email => email.length)]
      }
      if (this.emailsGroupList) {
        newSearchProfile.emailGroupsIds = this.emailsGroupList.map(g => g.emailGroupId)
      }
      if (!this.error) {
        if (this.createProfile) {
          await axios
              .post("/api/searchProfile", newSearchProfile)
              .then((response) => {
                if (response.data) {
                  if (response.data.responseCode !== "OK") {
                    this.error = {general: i18n.t('genios.monitoring.error.' + response.data.responseCode)}
                  }
                }
              })
              .catch(error => {
                this.error = {general: i18n.t('genios.monitoring.error.connection')};
                console.log(error);
              });
        } else {
            await axios
                .put("/api/searchProfile", newSearchProfile)
                .then((response) => {
                  if (response.data) {
                    if (response.data.responseCode !== "OK") {
                      this.error = {general: i18n.t('genios.monitoring.error.' + response.data.responseCode)}
                    }
                  }
                })
                .catch(error => {
                  this.error = {general: i18n.t('genios.monitoring.error.connection')};
                  console.log(error);
                });
        }
      }
      if (!this.error) {
        closeTransparentPopupModal();
      }
    },
    closeModal() {
      closeTransparentPopupModal();
    },
    loadSearchMask: function (navigationUrl) {
      fetchSearchMask(this.setSearchMaskCallback, navigationUrl);
    },
    setSearchMaskCallback: function (searchMask) {
      this.searchMask = searchMask;
    },
    useDefaultEmail() {
      if (this.schedule.emails.length === 0) {
        this.schedule.emails.push(this.sharedState?.defaultUserEmail || '');
      }
    },
    extractNavigation(url) {
      const pathSegments = url.split('?')[0].split('/');

      const topLevelNavigation = pathSegments[2] || null;
      const secondLevelNavigation = pathSegments[3] || null;

      return {
        topLevel: topLevelNavigation,
        secondLevel: secondLevelNavigation,
      };
    },
    getNavigation(name) {
      if (name === 'category') {
        return this.navigationLevel;
      }
      return name;
    },
  }
}
</script>

<style scoped>

</style>
