import {
    activateBookmarkListApi,
    addNewBookmarkListApi,
    clearBookmarkListApi,
    deleteBookmarkListApi,
    fetchNavigationItems,
    fetchSearchPurposes,
    fetchWatchLists,
    saveUserAdvancedSearchSettings,
    saveUserBookmarksResultSettings,
    saveUserSearchResultSettings,
    updateBookmarkListApi,
} from "../../fetching";
import Vuex from "vuex";
import {noneSearchPurpose, SortingBy, SortingOrder} from "../../consts";
import Vue from "vue";
import persistedState from "../plugins/persistedState";
import {updateUrlParams} from "../../utils/url_utils";

Vue.use(Vuex);

const solutions = [
    'bain',
    'bayernlb',
    'bmwi',
    'bmvg',
    'buergerschaftskanzlei',
    'cmshs',
    'bisnode',
    'dsgv',
    'eglv',
    'focus',
    'kbs',
    'kas',
    'l-bank',
    'lbbw',
    'mckinsey',
    'merck',
    'mhoch2',
    'rolandberger',
    'srf',
    'stiftung-warentest',
    'skkn',
    'territory'
];
const currentSolution = window.sharedState?.solution;

const initialFilterDisplaySettings = {
    advancedSearchOpened: solutions.includes(currentSolution),
    categoryFilterOpened: true,
    filtersOpened: true,
    userInteracted: false,
}

const initialBookmarksResultSettings = {
    size: 10,
    sortAndOrder: {
        sort: SortingBy.BY_DATE_CREATED,
        order: SortingOrder.DESCENDING
    },
    userInteracted: false
}

const initialSearchResultSettings = {
    size: 10,
    sortAndOrder: {
        sort: SortingBy.BY_DATE,
        order: SortingOrder.DESCENDING
    },
    userInteracted: false,
    useDecomposition: false,
    view: 'list',
}

const initialState = {
    meinGeniosNavItems: [],
    bookmarkList: [],
    selectedBookmarkList: {},
    activeWatchlistIds: [],
    searchPurposeList: [],
    selectedSearchPurposeId: null,
    userInteractionSearchFilterDisplaySettings: initialFilterDisplaySettings,
    searchFilterDisplaySettings: null,
    userInteractionSearchResultSettings: initialSearchResultSettings,
    searchResultSettings: initialSearchResultSettings,
    userInteractionBookmarksResultSettings: initialBookmarksResultSettings,
    bookmarksResultSettings: initialBookmarksResultSettings,
    searchResultSideNavLastPosition: null,
    showPanel: sessionStorage.getItem('showPanel'),
    loading: false,
    error: false,
    errorMessage: "",
    lastWebUrl: "/",
    ...window.sessionVariables?.['vuexState'] || {},
};

const store = new Vuex.Store({
    state: {...initialState},
    getters: {
        getMeinGeniosNavItems: state => state.meinGeniosNavItems,
        getBookmarkList: state => state.bookmarkList,
        getSelectedBookmarkList: state => state.selectedBookmarkList,
        getActiveWatchlistIds: (state) => state.activeWatchlistIds,
        getSearchPurposeList: state => state.searchPurposeList,
        getSelectedSearchPurposeId: state => state.selectedSearchPurposeId,
        getUserInteractionSearchFilterDisplaySettings: state => state.userInteractionSearchFilterDisplaySettings,
        getSearchFilterDisplaySettings: state => state.searchFilterDisplaySettings,
        getUserInteractionSearchResultSettings: state => state.userInteractionSearchResultSettings,
        getSearchResultSettings: state => state.searchResultSettings,
        getUserInteractionBookmarksResultSettings: state => state.userInteractionBookmarksResultSettings,
        getBookmarksResultSettings: state => state.bookmarksResultSettings,
        getSearchResultSideNavLastPosition: state => state.searchResultSideNavLastPosition,
        getShowPanel: state => state.showPanel,
        getLoading: state => state.loading,
        getError: state => state.error,
        getErrorMessage: state => state.errorMessage,
        getLastWebUrl: state => {
            return state.lastWebUrl;
        },
    },
    mutations: {
        setMeinGeniosNavItems(state, navItems) {
            state.meinGeniosNavItems = navItems;
        },
        setBookmarkList(state, bookmarkList) {
            state.bookmarkList = bookmarkList;
        },
        setSelectedBookmarkList(state, selectedBookmarkList) {
            if (state.selectedBookmarkList.userInteracted && !selectedBookmarkList.isUpdated) {
                //don't store
            } else {
                let selectedBookmarkListToSave = selectedBookmarkList;
                if (typeof selectedBookmarkList === 'string') {
                    const savedBookmark = state.bookmarkList.find(b => selectedBookmarkList === b.id.toString());
                    if (savedBookmark) {
                        selectedBookmarkListToSave = {
                            text: `${savedBookmark.name} (${savedBookmark.numberOfBookmarks || 0})`,
                            id: savedBookmark.id,
                        };
                    } else {
                        selectedBookmarkListToSave = {};
                    }
                } else {
                    const { isUpdated, ...rest } = selectedBookmarkList;
                    selectedBookmarkListToSave = rest;
                }
                state.selectedBookmarkList = selectedBookmarkListToSave;
            }
        },
        setActiveWatchlistIds(state, ids) {
            state.activeWatchlistIds = ids;
        },
        setSearchPurposeList(state, searchPurposeList) {
            state.searchPurposeList = [noneSearchPurpose, ...searchPurposeList];
        },
        setSelectedSearchPurposeId(state, id) {
            state.selectedSearchPurposeId = id;
        },
        setUserInteractionSearchFilterDisplaySettings(state, settings) {
            state.userInteractionSearchFilterDisplaySettings = settings;
        },
        setSearchFilterDisplaySettings(state, settings) {
            state.searchFilterDisplaySettings = settings;
        },
        setSearchResultSideNavLastPosition(state, position) {
            state.searchResultSideNavLastPosition = position;
        },
        setUserInteractionSearchResultSettings(state, setting) {
            const newSetting = {...setting};
            if (setting.sort) {
                newSetting.sortAndOrder = {
                    sort: setting.sort,
                    order: setting.order || SortingOrder.DESCENDING,
                };
            }
            state.userInteractionSearchResultSettings = newSetting;
            window.sharedState.sort = newSetting.sort;
            window.sharedState.order = newSetting.order;
            window.sharedState.pageSize = newSetting.size;
            window.sharedState.view = newSetting.view;
        },
        setSearchResultSettings(state, setting) {
            const newSetting = {...setting};
            if (setting.sort) {
                newSetting.sortAndOrder = {
                    sort: setting.sort,
                    order: setting.order || SortingOrder.DESCENDING,
                };
            }
            state.searchResultSettings = newSetting;
        },
        setUserInteractionBookmarksResultSettings(state, setting) {
            const newSetting = {...setting};
            if (setting.sort) {
                newSetting.sortAndOrder = {
                    sort: setting.sort,
                    order: setting.order || SortingOrder.DESCENDING,
                };
            }
            state.userInteractionBookmarksResultSettings = newSetting;
        },
        setBookmarksResultSettings(state, setting) {
            const newSetting = {...setting};
            if (setting.sort) {
                newSetting.sortAndOrder = {
                    sort: setting.sort,
                    order: setting.order || SortingOrder.DESCENDING,
                };
            }
            state.bookmarksResultSettings = newSetting;
        },
        setShowPanel(state, panel) {
            state.showPanel = panel;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload;
        },
        setLastWebUrl(state, url) {
            state.lastWebUrl = url;
        },
    },
    actions: {
        async fetchMeinGeniosNavItems({ commit }) {
            commit('setLoading', true);
            try {
                const navItems = await fetchNavigationItems();
                commit('setMeinGeniosNavItems', navItems);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchBookmarkList({ state, commit }) {
            commit('setLoading', true);
            try {
                let bookmarkList = await fetchWatchLists();
                bookmarkList.sort((a, b) => a.name.localeCompare(b.name));
                commit('setBookmarkList', bookmarkList);
                const selectedBookmarkList = state.selectedBookmarkList;
                if (selectedBookmarkList) {
                    const updatedSelection = bookmarkList.find(b => b.id === selectedBookmarkList.id);
                    if (updatedSelection) {
                        commit('setSelectedBookmarkList', {
                            ...selectedBookmarkList,
                            text: `${updatedSelection.name} (${updatedSelection.numberOfBookmarks || 0})`,
                            numberOfBookmarks: updatedSelection.numberOfBookmarks,
                            isUpdated: true
                        });
                    }
                }
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteBookmarkList({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await deleteBookmarkListApi(ids);
                await dispatch('fetchBookmarkList');
                await dispatch('setInitialActiveWatchlist');
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async clearBookmarkList({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await clearBookmarkListApi(ids);
                await dispatch('fetchBookmarkList');
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async updateBookmarkList({ commit, dispatch }, { id, name }) {
            commit('setLoading', true);
            try {
                await updateBookmarkListApi(id, name);
                await dispatch('fetchBookmarkList');
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        setInitialActiveWatchlist({ commit, state }) {
            const activeList = state.bookmarkList.filter(item =>
                item.categoryTags && item.categoryTags.includes('Active')
            );
            const fallbackList = state.bookmarkList.find(item => item.name === "Standard-Merkliste");
            const selectedBookmarkList = state.selectedBookmarkList;

            if (activeList && activeList.length > 0) {
                commit('setActiveWatchlistIds', activeList.map((list) => list.id));
                const bookmarkList = activeList[0];
                if (!selectedBookmarkList) {
                    commit('setSelectedBookmarkList', {
                        id: bookmarkList.id,
                        text: `${bookmarkList.name} (${bookmarkList.numberOfBookmarks || 0})`,
                        numberOfBookmarks: bookmarkList.numberOfBookmarks
                    });
                }
            } else if (fallbackList) {
                commit('setActiveWatchlistIds', [fallbackList.id]);
                if (!selectedBookmarkList) {
                    commit('setSelectedBookmarkList', {
                        id: fallbackList.id,
                        text: `${fallbackList.name} (${fallbackList.numberOfBookmarks || 0})`,
                        numberOfBookmarks: fallbackList.numberOfBookmarks
                    });
                }
            }
        },
        async activateBookmarkList({ commit, state, dispatch }, { id, isActive }) {
            commit('setLoading', true);
            try {
                await activateBookmarkListApi(id, isActive);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async addNewBookmarkList({ commit, dispatch }, listName) {
            commit('setLoading', true);
            try {
                await addNewBookmarkListApi(listName);
                await dispatch('fetchBookmarkList');
                commit('setError', false);
                commit('setErrorMessage', "");
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchSearchPurposeList({ commit }) {
            commit('setLoading', true);
            try {
                const searchPurposeList = await fetchSearchPurposes();
                commit('setSearchPurposeList', [...searchPurposeList]);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async saveAdvancedSearchSettings({ commit }, settingsToSave) {
            commit('setLoading', true);
            try {
                const { data } = await saveUserAdvancedSearchSettings(settingsToSave);
                if (data) {
                    commit('setSearchFilterDisplaySettings', {...data});
                    commit('setUserInteractionSearchFilterDisplaySettings', {...data, userInteracted: true});
                }
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async saveSearchResultSettings({ commit, getters }, settingsToSave) {
            commit('setLoading', true);
            try {
                const dataToSave = {
                    useDecomposition: settingsToSave.useDecomposition.value || settingsToSave.useDecomposition,
                    size: parseInt(settingsToSave.size.value || settingsToSave.size),
                    sort: settingsToSave.sortAndOrder.sort,
                    order: settingsToSave.sortAndOrder.order,
                    view: settingsToSave.view?.value || settingsToSave.view,
                };
                const { data } = await saveUserSearchResultSettings(dataToSave);
                if (data) {
                    commit('setSearchResultSettings', {...data});
                    commit('setUserInteractionSearchResultSettings', {...data,  userInteracted: true});
                    const lastSearchUrl = getters.getLastWebUrl;
                    const updated = updateUrlParams({...dataToSave}, lastSearchUrl);
                    store.commit('setLastWebUrl', updated);
                }
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async saveBookmarksResultSettings({ commit }, settingsToSave) {
            commit('setLoading', true);
            try {
                const dataToSave = {
                    ...settingsToSave,
                    size: settingsToSave.size.value || settingsToSave.size,
                };
                const { data } = await saveUserBookmarksResultSettings(dataToSave);
                if (data) {
                    commit('setBookmarksResultSettings', {...data});
                    commit('setUserInteractionBookmarksResultSettings', {...data,  userInteracted: true});
                }
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
    },
    plugins: [persistedState]
});

export default store;