import axios from "axios";
import {changeHistory} from "../utils/history_utils";
import {setupPaymentFormComponent} from "../setups/vue-components";
import {PaymentMode} from "../components/payment";

export const getNextDocumentId = async function (documentId, recursionDepth = 0) {
    let nextDocumentId;
    const documentIds = window.sharedState.fullArticleModalList;
    const fetchHigherDocumentIds = window.fetchHigherDocumentIds;
    if (!!documentIds) {
        const currentPosition = documentIds.indexOf(documentId);
        if (currentPosition > -1 && currentPosition < (documentIds.length - 1)) {
            nextDocumentId = documentIds[currentPosition + 1];
        } else if (!!fetchHigherDocumentIds) {
            const moreAvailable = await fetchHigherDocumentIds();
            if (!!moreAvailable && recursionDepth < 3) {
                nextDocumentId = getNextDocumentId(documentId, recursionDepth + 1);
            }
        }
    }
    return nextDocumentId;
}

export const getPreviousDocumentId = async function (documentId, recursionDepth = 0) {
    let previousDocumentId;
    const documentIds = window.sharedState.fullArticleModalList;
    const fetchLowerDocumentIds = window.fetchLowerDocumentIds;
    if (!!documentIds) {
        const currentPosition = documentIds.indexOf(documentId);
        if (currentPosition > 0) {
            previousDocumentId = documentIds[currentPosition - 1];
        } else if (!!fetchLowerDocumentIds) {
            const moreAvailable = await fetchLowerDocumentIds();
            if (!!moreAvailable && recursionDepth < 3) {
                previousDocumentId = getPreviousDocumentId(documentId, recursionDepth + 1);
            }
        }
    }
    return previousDocumentId;
}

export const getUser = async () => {
    try {
        const {data} = await axios.get(`/api/getUserInfo`)
        window.sharedState.userInfo.isConfirmed = data
    } catch (e) {
        console.log('getUser:error => ', e)
        console.dir(e)
    }
}


export const getVideoData = async (params, body) => {
    try {
        const {videoId, clientType, type, documentId} = params

        const {data} = await axios.post(`/video/${documentId}`, body, {params})
        if (data.code && data?.code !== "VIDEO_PAYMENT_SUCCESS") {
            changeHistory({video: videoId})
            await setupPaymentFormComponent({
                ...data,
                type,
                videoId,
                clientType
            }, documentId, PaymentMode.video)
            return null
        } else if (data?.errorMessage) {
            alert(data.errorMessage)
            return null
        } else {
            changeHistory({video: ""})
            return data
        }
    } catch (e) {
        console.log("getVideoData:error => ", e)
        console.dir(e)
        return null
    }
}
