<template>
  <mein-genios-template :pageTitle="$t('meinGenios.searchProfile.title')" :pageInfo="$t('meinGenios.searchProfile.info')" class="search-history">
    <template v-slot:content>
      <div v-if="hasSearchProfiles" class="flex-column-max-height">
        <header-layout
            :action-bar-config="actionBarConfig"
            @remove-all="removeAll"
            @update:selected-all="selectAll($event)"
            @update-list="updateList"
            class="search-history__action-bar">
        ></header-layout>

        <custom-table-component
            class="search-history__table"
            :customTableArray="customTableArray"
            @column-event="handleColumnEvent"
            @item-clicked="handleItemClicked"
        />
        <pagination-component
            v-if="pagesQuantity > 1"
            :pages="pagesQuantity"
            :selected="currentPage"
            @update-page="updateCurrentPage"
        />
        <info-overlay-component
            v-if="showFiltersModal"
            :modal-data="modalContent"
            v-click-outside="onClickOutside"
            @primary-action="searchAgain"
            @secondary-action="closeInfoOverlay"
        >
          <template v-for="(value, name) in activeFilters">
            <active-filter-tag
                v-for="filter in value"
                :key="name+':'+filter"
                :element="filter"
                :removable="false"
                :type="name"
            />
          </template>
        </info-overlay-component>
      </div>
      <div v-else>
        <empty-content-placeholder :icon="'search-outline'" :message="$t('meinGenios.searchProfile.empty')" :is-loading="isLoading"></empty-content-placeholder>
      </div>

    </template>
  </mein-genios-template>
</template>

<script>
import MeinGeniosTemplate from "./MeinGeniosTemplate.vue";
import HeaderLayout from "./common/HeaderLayout.vue";
import {ActionBarType} from "../../../functions/consts";
import searchProfileModule from "../../../functions/store/modules/searchProfile";
import ActionBar from "./common/ActionBar.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import CustomTableComponent from "../common/CustomTableComponent.vue";
import EmptyContentPlaceholder from "../styled/EmptyContentPlaceholder.vue";
import PaginationComponent from "../styled/PaginationComponent.vue";
import {queryParamMixin} from "../../../functions/setups/vue-components/queryParamMixin";
import InfoOverlayComponent from "../common/InfoOverlayComponent.vue";
import ActiveFilterTag from "../Filters/ActiveFilterTag.vue";
import {getExtendedQueryText} from "../../../functions/getExtendedQueryTextFromAnalysis";

export default {
  name: "SearchProfileComponent.vue",
  components: {
    ActiveFilterTag,
    InfoOverlayComponent,
    PaginationComponent,
    EmptyContentPlaceholder,
    CustomTableComponent,
    CheckboxFormElement,
    ActionBar,
    HeaderLayout,
    MeinGeniosTemplate,
  },
  mixins: [queryParamMixin],
  data() {
    return {
      moduleName: 'searchProfileModule',
      showFiltersModal: false,
      searchAgainUrl: null,
      modalContent: {},
      activeFilters: [],
      dropdownActionBarButtons: ActionBarType.DROPDOWN_SEARCH_PROFILES,
      actionBarButtons: ActionBarType.SEARCH_PROFILES,
      cutTextValue: 70,
      headers: [
        {
          key: 'checkbox',
          label: '',
          columnWidth: '10px',
          render: 'CheckboxFormElement',
          props: (row) => ({
            element: row.checkboxObj.element,
            passedValue: row.checkboxObj.passedValue,
          }),
        },
        { key: 'name', label: this.$t('meinGenios.searchProfile.table.name'), columnWidth: '28vw' },
        { key: 'query', label: this.$t('meinGenios.searchProfile.table.query'), columnWidth: '28vw', isClickable: true },
        { key: 'source', label: this.$t('meinGenios.searchProfile.table.source'), columnWidth: '28vw' },
        {
          key: 'action',
          label: '',
          render: "ActionBar",
          props: (row) => ({
            actionBarButtons: row.actionBarObject.actionBarButtons,
            selectedList: row.actionBarObject.selectedList,
            allDocumentsOnPage: row.actionBarObject.allDocumentsOnPage,
          }),
        },
      ]
    };
  },
  computed: {
    searchProfiles() {
      return this.$store.getters['searchProfileModule/getSearchProfiles'](this.currentPage) || [];
    },
    hasSearchProfiles() {
      return this.searchProfiles.length > 0;
    },
    selectedSearchProfiles() {
      return this.$store.getters['searchProfileModule/getSelectedSearchProfiles'];
    },
    pagesQuantity() {
      return (this.$store.getters['searchProfileModule/getTotalNumberOfPages'] || 0 )
    },
    isLoading() {
      return this.$store.getters['searchProfileModule/getLoading'];
    },
    isAllSelected() {
      return this.searchProfiles.length > 0 && this.searchProfiles.every(search => this.selectedSearchProfiles.map(profile => profile.profileId).includes(search.profileId));
    },
    actionBarConfig() {
      return {
        dropdownActionBarButtons: this.dropdownActionBarButtons,
        selectedArticles: this.selectedSearchProfiles.map(item => ({
          ...item,
          title: item.name
        })),
        isAllSelected: this.isAllSelected,
        allDocumentsOnPage: this.searchProfiles,
        shouldDisplaySortBar: false
      }
    },
    getRows() {
      return this.searchProfiles.map(item => {
        const extendedQueryObject = getExtendedQueryText(item.analysis, this.cutTextValue);
        return {
          id: item.profileId,
          name: item.name,
          query: extendedQueryObject.query,
          queryText: extendedQueryObject.queryText,
          isClickable: extendedQueryObject.isClickable,
          availableFilters: extendedQueryObject.availableFilters,
          searchAgainUrl: item.analysis.url,
          source: item.analysis?.source?.join(', ') || item.analysis?.category?.join(', ') || item.analysis?.navigation,
          checkboxObj: {
            element: {
              id: item.profileId,
              name: '',
              fieldLabel: '',
              field: false,
              error: false,
            },
            passedValue: this.selectedSearchProfiles.some(profile => profile.profileId === item.profileId),
          },
          actionBarObject: {
            actionBarButtons: this.actionBarButtons,
            selectedList: [{...item, availableFilters: extendedQueryObject.availableFilters}],
          }
      }})
    },
    currentPage() {
      return this.$store.getters['searchProfileModule/getCurrentPage'];
    },
    customTableArray() {
      return {
        columns: this.headers,
        rows: this.getRows,
      };
    },
  },
  async created() {
    if (!this.$store.hasModule('searchProfileModule')) {
      this.$store.registerModule('searchProfileModule', searchProfileModule);
    }
  },
  async mounted() {
    const query = this.getQueryParams();
    this.saveQueryParamsToStore(query);
    if (query) {
      await this.loadSearchProfiles();
    }

    this.updateRoute();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    this.$store.commit('searchProfileModule/resetState');
  },
  methods: {
    async loadSearchProfiles() {
      await this.$store.dispatch('searchProfileModule/fetchSearchProfiles');
    },
    selectAll(event) {
      if (event) {
        this.$store.commit('searchProfileModule/setSelectedSearchProfiles', [...this.searchProfiles]);
      } else {
        const remainingSelectedSearchProfiles = this.selectedSearchProfiles.filter(
            item1 => !this.searchProfiles.some(item2 => item1.id === item2.id)
        );
        this.$store.commit('searchProfileModule/setSelectedSearchProfiles', remainingSelectedSearchProfiles);
      }
    },
    removeAll() {
      this.$store.commit('searchProfileModule/setSelectedSearchProfiles', []);
    },
    async updateList() {
      const oldPage = this.currentPage;
      this.$store.commit('searchProfileModule/setSearchProfiles', []);
      this.$store.commit('searchProfileModule/setSelectedSearchProfiles', []);
      this.$store.commit('searchProfileModule/setCurrentPage', 1);
      this.updateRoute();
      if (oldPage === 1) {
        await this.loadSearchProfiles();
      }
    },
    handleResize() {
      this.cutTextValue = this.getCutTextValue();
    },
    getCutTextValue() {
      const containerWidth = window.innerWidth * 0.4;
      const averageCharWidth = 8;
      return Math.floor(containerWidth / averageCharWidth);
    },
    handleColumnEvent({ row, key, value }) {
      if (key === "checkbox") {
        const isSearchProfileSaved = this.selectedSearchProfiles.find(item => item.profileId === row.id);
        if (value && isSearchProfileSaved || !value && !isSearchProfileSaved) {
          return;
        }
        const newSelectedSearchProfiles = this.searchProfiles.find(item => item.profileId === row.id);
        if (value && newSelectedSearchProfiles) {
          this.$store.commit('searchProfileModule/setSelectedSearchProfiles',[...this.selectedSearchProfiles, newSelectedSearchProfiles]);
        } else {
          this.$store.commit('searchProfileModule/setSelectedSearchProfiles', this.selectedSearchProfiles.filter(item => item.profileId !== row.id));
        }
      }
      if (key === "action") {
        this.updateList();
      }
    },
    async updateCurrentPage(page) {
      if (page !== this.currentPage) {
        this.$store.commit('searchProfileModule/setCurrentPage', page);
        this.updateRoute();
      }
    },

    handleItemClicked({ row }) {
      this.activeFilters = row.availableFilters;
      this.searchAgainUrl = row.searchAgainUrl;
      this.modalContent = {
        title: this.$t(`meinGenios.searchProfile.searchQueryPopup.title`),
        description: row.queryText,
        primaryButtonText: this.$t(`meinGenios.searchProfile.searchQueryPopup.primaryButton`),
        secondaryButtonText: this.$t(`meinGenios.searchProfile.searchQueryPopup.secondaryButton`),
        standardColor: true,
      };
      this.showFiltersModal = true;
    },
    searchAgain() {
      const url = this.searchAgainUrl;
      this.searchAgainUrl = null;
      this.showFiltersModal = false;
      this.activeFilters = [];
      if (url) {
        window.location.href = url;
      }
    },
    closeInfoOverlay() {
      this.showFiltersModal = false;
      this.activeFilters = [];
    },
    onClickOutside() {
      this.showFiltersModal = false;
      this.activeFilters = [];
    },
    updateRoute() {
      this.syncQueryParams({
        page: this.currentPage,
      });
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      async handler(query) {
        if (query && this.$store.hasModule(this.moduleName)) {
          this.saveQueryParamsToStore(query);
          await this.loadSearchProfiles();
        }
      },
    },
  },
};
</script>
