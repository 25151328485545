<script>

export default {
  name: "MessageComponent",
  props: {
    message: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      message : ""
    };
  }
}
</script>

<template>
      <div id="message" class="message-container">{{message}}</div>
</template>

<style>
.message-container {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 500px;
  padding: 10px;
}

</style>