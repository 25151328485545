// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.message-container {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 500px;
  padding: 10px;
}

`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/MessageComponent.vue"],"names":[],"mappings":";AAuBA;EACA,qBAAA;EACA,qBAAA;EACA,yBAAA;EACA,gBAAA;EACA,aAAA;AACA","sourcesContent":["<script>\n\nexport default {\n  name: \"MessageComponent\",\n  props: {\n    message: {\n      type: String,\n      required: true\n    }\n  },\n  data() {\n    return {\n      message : \"\"\n    };\n  }\n}\n</script>\n\n<template>\n      <div id=\"message\" class=\"message-container\">{{message}}</div>\n</template>\n\n<style>\n.message-container {\n  white-space: pre-wrap;\n  word-wrap: break-word;\n  overflow-wrap: break-word;\n  max-width: 500px;\n  padding: 10px;\n}\n\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
