//ActionBar, document-addition-info.ftl
import {setupLoginComponent} from "../setups/vue-components";
import {openSendEmailOverlay, openTransparentPopupModal} from "../opens";
import {closeTransparentPopupModal} from "../closing";

export const setupSendEmailButtons = function (selector = document) {
    const emailBtns = selector.querySelectorAll('#action_bar_marker button[js-handler="send_email"]') //send_email buttons for grid view
    if (emailBtns.length) {
        emailBtns.forEach(emailBtn => {
            const sendEmailHandler = () => {
                if (!window.sharedState.loginStatus?.loggedIn) {
                    const contentContainer = document.querySelector("#overlay_content")
                    contentContainer.innerHTML = "<div id='embedded_login'></div>"
                    openTransparentPopupModal()
                    setupLoginComponent("#embedded_login", "genios.login.reasons.login", function () {
                        closeTransparentPopupModal()
                        const documentId = emailBtn.dataset?.documentId || emailBtn.getAttribute('id').split('__mail')[0]
                        const element = emailBtn.closest(`.article[data-document-id=${documentId}]`)
                        element.focus()
                        setTimeout(function () {
                            openSendEmailOverlay('general', defineSendEmailList(documentId));
                        }, 600);
                    })
                } else {
                    const documentId = emailBtn.dataset?.documentId || emailBtn.getAttribute('id').split('__mail')[0]
                    openSendEmailOverlay('general', defineSendEmailList(documentId));
                }
            }
            emailBtn.removeEventListener('click', sendEmailHandler)
            emailBtn.addEventListener('click', sendEmailHandler)
        })
    }
}

export const defineSendEmailList = function (id = null) {
    if (id) return [id]

    const selectedDocuments = JSON.parse(sessionStorage.getItem('selectedDocuments')) || []
    if (selectedDocuments.length) return selectedDocuments.map(item => item.docId)

    const ids = document.querySelectorAll('.grid-view .element') || []
    if (ids.length) return Array.from(ids).map(item => item.getAttribute('data-document-id'))

    return [window.sharedState.urlTopLevelNavigation]
}