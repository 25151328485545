import {formatDate} from "./utils/date_utils";
import i18n from "../vue/components/plugins/Translations.vue";

export const getExtendedQueryText = function (analysis, cutTextValue) {
    if (!hasExtendedText(analysis)) {
        const queryText = analysis.query || '';
        const isTruncated = queryText.length > cutTextValue;
        return {
            query: isTruncated
                ? `<span class="standard-bold">${queryText.substring(0, cutTextValue)}</span> ... <span class="simulate-link">${ i18n.t('meinGenios.searchHistory.more')}</span>`
                : `<span class="standard-bold">${queryText}</span>`,
            isClickable: isTruncated,
            queryText: queryText,
        };
    }
    let queryText = {
        value: [analysis.query],
    };

    const flatResult = {};

    if (Array.isArray(analysis.advanced)) {
        analysis.advanced.forEach(item => {
            if (item.value) {
                flatResult[item.label] = [item.value];
            } else if (item.index !== undefined) {
                flatResult[item.label] = [item.index];
            }
            if (!queryText.value[0] && Object.keys(flatResult).length > 0) {
                queryText = addQueryTextValueFromExistingObjects(flatResult);
            }

            if (item.from && item.to) {
                flatResult[item.label] = [{from: item.from, to: item.to}];
            }

            if (!queryText.value[0] && !!(item.from || item.to)) {
                if (item.label) {
                    queryText = {
                        value: [`${item.from || ''} - ${item.to || ''}`],
                        label: item.label
                    }
                }
            }
        });
    }

    const stringListFields = ['category', 'source', 'industry', 'company', 'topic', 'region', 'person', 'author', 'instructor'];
    const result = extractExtendedSearchObjects(stringListFields, analysis);

    for (const [, values] of Object.entries(result)) {
        values.forEach(entry => {
            for (const [subKey, subValue] of Object.entries(entry)) {
                flatResult[subKey] = flatResult[subKey] || [];
                flatResult[subKey].push(subValue);
            }
        });
    }
    if (!queryText.value[0] && Object.keys(flatResult).length > 0) {
        queryText = addQueryTextValueFromExistingObjects(flatResult);
    }

    if (analysis.date && typeof analysis.date === 'object') {
        let from = analysis.date.from;
        let to = analysis.date.to;
        if (from) {
            from = formatDate(analysis.date.from);
        }
        if (to) {
            to = formatDate(analysis.date.to);
        }
        flatResult.date = [{
            from: from || '',
            to: to || '',
        }];
        if (!queryText.value[0] && !!(from || to)) {
            queryText = {
                value: [`${from || ''} - ${to || ''}`],
                label: 'date'
            }
        }
    }
    const isClickable = Object.keys(flatResult).length > 0 || queryText.value[0] !== analysis.query;
    const label = queryText.label ? ` (${resolveMessage('genios.' +  queryText.label.toLowerCase(), queryText.label)}) ` : '';
    const textValue = queryText.value[0] === 'true' ? '' : queryText.value[0];
    const text = textValue.length > cutTextValue
        ? `<span class="standard-bold">${textValue.substring(0, cutTextValue)}</span>`.concat(`${label}`).concat(` ... <span class="simulate-link">${ i18n.t('meinGenios.searchProfile.more')}</span>`)
        : `<span class="standard-bold">${textValue}</span>`.concat(`${label}`).concat(isClickable ? ` ... <span class="simulate-link">${ i18n.t('meinGenios.searchProfile.more')}</span>` : '');
    return {
        query: text,
        queryText: analysis.query,
        isClickable: isClickable,
        availableFilters: flatResult
    };
}

function hasExtendedText(analysis) {
    return  Object.keys(analysis).some(key =>
        key !== 'query' &&
        key !== 'navigation' &&
        Array.isArray(analysis[key]) &&
        analysis[key].length > 0 ||
        key === 'date' &&
        typeof analysis[key] === 'object'
    );
}

function addQueryTextValueFromExistingObjects(flatResult) {
    const firstKey = Object.keys(flatResult)[0];
    if (firstKey) {
        return  {
            value: [`${flatResult[firstKey]}`],
            label: firstKey
        }
    }
}

function extractExtendedSearchObjects(stringListFields, analysis) {
    const result = {};
    stringListFields.forEach(field => {
        if (Array.isArray(analysis[field])) {
            analysis[field].forEach(value => {
                result[field] = result[field] || [];
                result[field].push({[field]: value});
            });
        }
    });
    return result;
}

function resolveMessage(key, fallback) {
    const displayText = i18n.t(key);
    if (displayText === key) {
        return fallback;
    }
    return displayText;
}
