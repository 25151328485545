<template>
  <div class="bookmark-box">
    <div class="dropdown-and-text">
      <div v-if="bookmarkList.length > 0" class="bookmark-list-dropdown" :style="{ backgroundColor: isBookmarkListsExpanded ? 'transparent' : 'var(--background-color)' }">
        <DropdownFormElement
            v-if="selectedBookmarkList.id"
            :element="{
              fieldLabel: $t('meinGenios.bookmarks.title'),
              options: bookmarkList
            }"
            :disabled="isBookmarkListsExpanded"
            :passed-value="selectedBookmarkList"
            @modified="handleModified"
            :style="{ borderRadius: '25px'}"
        />
      </div>
      <div v-if="windowWidth > 768" class="edit-bookmark-lists-text" @click.stop="toggleExpandedView">
        <div class="edit-option">{{ $t('genios.bookmarkList.edit') }}</div>
        <ion-icon :name="isBookmarkListsExpanded ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
      </div>
    </div>

    <bookmark-list
        v-if="watchlists.length > 0 && isBookmarkListsExpanded && windowWidth > 768"
        :watchlists="watchlists"
        class="bookmarklist-list"
    />
  </div>
</template>

<script>
import DropdownFormElement from "../../FormElements/DropdownFormElement.vue";
import BookmarkList from "./BookmarkList.vue";

export default {
  name: "BookmarkListsBox",
  components: {
    DropdownFormElement,
    BookmarkList,
  },
  props: {
    bookmarkList: {
      type: Array,
      required: true,
    },
    watchlists: {
      type: Array,
      required: true,
    },
    selectedBookmarkList: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isBookmarkListsExpanded: false,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleModified(event) {
      this.$emit('modified', event);
    },
    toggleExpandedView() {
      this.isBookmarkListsExpanded = !this.isBookmarkListsExpanded;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  }
};
</script>

<style scoped>
.bookmark-box {
  background: var(--shade-light);
  width: 100%;
  flex-shrink: 0;
  padding: 10px 23px 10px 12px;
}

@media (max-width: 768px) {
  .bookmark-box {
    padding: 10px;
  }
}

.edit-option {
  font-size: 16px;
}

.dropdown-and-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
}

.bookmark-list-dropdown {
  min-width: 200px;
  height: 42px;
  flex-shrink: 0;
  z-index: 12;
  border-radius: 25px;
}

.bookmark-list-dropdown .form_element.dropdown {
  border-radius: 25px;
  border: 1px solid var(--shade-color);
  background: var(--background-color);
}

.bookmark-list-dropdown .dropdown .selected-option  {
  color: var(--color-block);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bookmark-list-dropdown .form_element .dropdown__options {
  z-index: 200 !important;
}

.edit-bookmark-lists-text {
  display: flex;
  align-items: center;
  color: var(--foreground-color);
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  margin-left: auto;
  gap: 5px;
}

.edit-bookmark-lists-text ion-icon {
  width: 24px;
  height: 24px;
}

.bookmarklist-list {
  margin-top: 21px;
}
</style>
