import {changeHistory} from "../utils/history_utils";
import updateSession from "../updateSession";

export const changeSortingOrderHistory = function () {
    const menuButton = document.getElementById("settings_menu_button")
    if (!!menuButton) {
        //ensure sorting order matches url
        const queryString = window.location.search; //requestText=&partial=false&view=grid&sort=BY_RELEVANCE&order=desc
        const urlParams = new URLSearchParams(queryString);

        const sortValue = urlParams.get('sort') || window.sharedState.sort || "BY_RELEVANCE";
        const orderValue = urlParams.get('order') || window.sharedState.order || "desc";
        const sizeValue = urlParams.get('size') || window.sharedState.pageSize || 10;
        const viewValue = urlParams.get('view') || window.sharedState.view || 'list';

        changeHistory({order: orderValue, sort: sortValue, size: sizeValue, view: viewValue}, false);
        updateSession(viewValue, sortValue, orderValue, sizeValue);

        window.sharedState.sort = sortValue;
        window.sharedState.order = orderValue;
        window.sharedState.pageSize = sizeValue;
        window.sharedState.view = viewValue;
    }
}