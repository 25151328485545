<template>
  <div
      class="sidePanel"
      :style="{ width: sizePanel === 'closed' ? 'auto' : '254px' }"
  >
    <div class="searchResult__filters fixed">
      <overlay-scrollbars
          v-if="showOverlayScrollbarSection"
          class="sidenav"
          :options="osOptions"
          :style="{ width: sizePanel === 'closed' ? 'auto' : '254px' }"
      >
        <template v-if="sizePanel === 'open'">
          <LeftNavigationDrawerComponent
              :title="$t('genios.menu')"
              :menu-items="meinGeniosMenuItems"
              :is-mobile="false"
          />
        </template>
      </overlay-scrollbars>
    </div>
    <div
        class="divider vertical-resizable-divider"
        :style="{
        marginLeft: sizePanel === 'closed' ? '20px' : '254px',
        borderLeft: '1px solid var(--shade-color)'
      }"
    >
      <Button
          type="button"
          variant="secondary"
          :icon="sizePanel === 'open' ? 'chevron-back-outline' : 'chevron-forward-outline'"
          :toggle-text="false"
          @click.stop="togglePanel"
          class="divider-button"
          aria-label="hide"
      />
    </div>
  </div>
</template>



<script>

import AdvancedSearchComponent from "../../AdvancedSearch/AdvancedSearchComponent.vue";
import VerticalDraggableDivider from "../../VerticalDraggableDivider.vue";
import Toggle from "../../styled/Toggle.vue";
import Button from "../../styled/Button.vue";
import DateFilter from "../../Filters/DateFilter.vue";
import CheckBoxFilter from "../../Filters/CheckBoxFilter.vue";
import LeftNavigationDrawerComponent from "./LeftNavigationDrawerComponent.vue";
import {OverlayScrollbarsComponent} from "overlayscrollbars-vue";

export default {
  name: "LeftNavigationSidePanel.vue" ,
  props: {
    meinGeniosMenuItems: Array,
  },
  components: {LeftNavigationDrawerComponent, CheckBoxFilter, DateFilter, Button, Toggle, VerticalDraggableDivider,
    AdvancedSearchComponent, 'overlay-scrollbars': OverlayScrollbarsComponent},
  data() {
    return {
      osOptions: {
        clipAlways: false,
        scrollbars: {autoHide: 'move', clickScrolling: true},
        callbacks: {
          onScroll: this.onScroll
        }
      },
      sizePanel: 'open',
      showOverlayScrollbarSection: false
    }
  },
  async mounted() {
    this.showOverlayScrollbarSection = true;
    this.rerenderDividedComponents(this.sizePanel);
  },
  methods: {
    togglePanel() {
      let currentSizePanel = this.sizePanel;
      if (currentSizePanel === 'open') {
        this.closeSidePanel();
      } else if (currentSizePanel === 'closed') {
        this.openSidePanel();
      }
    },
    openSidePanel() {
      this.sizePanel = 'open';
      this.rerenderDividedComponents(this.sizePanel);
    },
    closeSidePanel() {
      this.sizePanel = 'closed';
      this.rerenderDividedComponents(this.sizePanel);
    },
    rerenderDividedComponents(showPanel) {
      let sidePanel = document.querySelector('.sidePanel');
      const searchResult = document.querySelector('.searchResult');
      const partialSearchResult = document.querySelector('#partialSearchResult');
      if (sidePanel) {
        sidePanel.classList.toggle("display-on-top", showPanel === 'open');
      }
      if (searchResult) {
        searchResult.classList.toggle("background-overlay", showPanel === 'open');
      }
      if (partialSearchResult) {
        partialSearchResult.classList.toggle("background-overlay", showPanel === 'open');
      }
    },
  }
}
</script>

<style scoped>
.searchResult__filters {
  top: 64px;
}
@media screen and (max-width: 1000px) {
  .searchResult__filters {
    top: 120px;
  }
}

</style>