<template>

  <Button
      v-if="dataIssueDatabase && buttonText"
      :data-e-paper-id="issueId"
      :variant="effectiveClass"
      :disabled="loading"
      @click="order"
      :loading="loading"
      :text="$t(buttonText, {price: buttonPrice})"
      icon="download-outline"
      ref="ePaperDownload"
      position="left"
      :toggle-text="false"
  />
</template>

<script>
import axios from "axios";
import qs from "qs";

import i18n from "../plugins/Translations.vue";

import Button from "../styled/Button.vue";
import ButtonLoader from "../styled/ButtonLoader.vue";

import eventBus from "../../../eventBus";

import {setupPaymentFormComponent} from "../../../functions/setups/vue-components";
import {openTransparentPopupModal} from "../../../functions/opens";
import {PaymentMode} from "../../../functions/components/payment";
import isIOSDevice from '../../../functions/utils/isIOSDevice';
import {formatPrice} from "../../../functions/utils/string_utils";


export default {
  name: "EPaperDownloadButton",
  components: {Button, ButtonLoader, i18n},
  data() {
    return {
      buttonText: false,
      buttonClass: "primary",
      buttonPrice: "",
      issueId: this.dataIssueId,
      issueDate: this.dataIssueDate,
      loading: false
    };
  },
  props: {
    dataIssueId: String,
    dataIssueDatabase: String,
    dataIssueDate: String,
    dataLoginStatus: Object
  },
  computed: {
    loggedIn: function () {
      return this.dataLoginStatus ? this.dataLoginStatus.loggedIn : false;
    },
    effectiveClass: function () {
      return this.loading ? "secondary" : this.buttonClass;
    }
  },
  created() {
    this.fetchDetails();
  },
  mounted() {
    eventBus.$on("disable", () => {
      this.loading = false
    })
  },
  watch: {
    loggedIn() {
      this.fetchDetails();
    },
    issueId() {
      this.fetchDetails();
    }
  },
  methods: {
    order(event, orderDataId, token, params) {
      this.loading = true;
      const fullParams = {
        issueId: this.issueId,
        issueDate: this.issueDate,
        orderDataId: orderDataId,
        gRecaptchaResponse: token,
        ...params
      };

      axios({
        method: "POST",
        url: "/api/orderEPaper",
        data: fullParams
      })
          .then(response => {
            const jsonData = response.data;
            if (jsonData.code === "REDIRECT_DOWNLOAD_E_PAPER") {
              this.fetchStream(jsonData.downloadAttachmentModel.redirectUrl);
              return;
            }
            const self = this;
            window.retryOrder = function (orderDataId, token, params) {
              self.order(event, orderDataId, token, params);
            }
            setupPaymentFormComponent({...jsonData, issueDate: this.issueDate}, this.issueId, PaymentMode.ePaper);
          })
          .catch(error => {
            this.displayError();
            console.error(error);
          })
    },
    fetchStream(url) {
      axios.get(url, {
        responseType: "blob"
      }).then(response => {
        this.download(response.data);
        this.fetchDetails();
      })
    },
    fetchDetails() {
      if (this.dataIssueDatabase && (!this.issueId || !this.issueDate)) {
        //need to fetch the latest issue for database
        axios.get("/api/issue/latest", {
          params: {
            database: this.dataIssueDatabase
          }
        }).then(response => {
          const issueInfo = response.data;
          this.issueId = issueInfo.issueId;
          this.issueDate = issueInfo.issueDate;
        }).catch(error => {
          console.error(error);
        })
      } else {
        axios.get("/api/getEPaperInfo", {
          params: {
            issueId: this.issueId,
            issueDate: this.issueDate
          }
        }).then(response => {
          if (response.data.error) {
            //console.log(response.data.errorMessage);
            return;
          }
          if (response.data.paid) {
            this.buttonClass = "secondary";
            this.buttonText = "genios.search.button.ePaper.paid";
            this.buttonPrice = "";
          } else {
            this.buttonText = response.data.displayKey;
            this.buttonPrice = response.data.price ? formatPrice(response.data.price) : '';
            this.buttonClass = "primary";
          }
        }).catch(error => {
          console.error(error);
        });
      }
    },
    displayError: function () {
      const contentContainer = document.querySelector("#overlay_content");
      contentContainer.innerHTML = "<h2>" + i18n.t("genios.document.error.unavailable") + "</h2>";
      openTransparentPopupModal();
    },
    download: function (response) {
      let url = window.URL.createObjectURL(response);

      // Downloading the file with a proper name
      const link = document.createElement('a');
      link.href = url;
      if (!isIOSDevice()) {
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loading = false;
    }

  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
button:disabled {
  opacity: 0.5;
  cursor: progress;
}


</style>
