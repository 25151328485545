<template>
  <div>
    <div class="header-layout--action-bar--container">
      <template v-for="button in sortedVisibleButtons">
      <div class="tooltip tooltip-icon">
        <Button
            :key="button.name"
            :name="button.name"
            variant="icon"
            :icon="button.icon"
            :aria-label="button.name"
            @click="button.clickFunction"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">
          <span v-if="button.name === 'toggle-monitoring'">
            {{ $t(`genios.tooltip.button.toggle-monitoring.${button.icon}`) }}
          </span>
          <span v-else>
            {{ $t(`genios.tooltip.button.${button.name}`) }}
          </span>
        </div>
      </div>
      </template>
    </div>
    <InfoOverlayComponent
        v-if="showInfoOverlayComponent"
        :type="modalType"
        :modal-data="modalContent"
        :initial-input-value="inputInitialValue"
        @primary-action="modalContent.primaryAction"
        @secondary-action="modalContent.secondaryAction"
    />
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import {
  openConfirmPurchaseOverlay,
  openOverlay,
  openSendEmailOverlay,
  openTransparentPopupModal,
  openWatchlistAddOverlay,
  openWatchlistDeleteOverlay
} from "../../../../functions/opens";
import {setupLoginComponent} from "../../../../functions/setups/vue-components";
import {closeTransparentPopupModal} from "../../../../functions/closing";
import SendEmailComponent from "../../ActionBar/SendEmailComponent.vue";
import {checkLogin} from "../../../../functions/checks";
import {downloadCSV} from "../../../../functions/utils/download_documents_as_CSV";
import {convertToZulu} from "../../../../functions/utils/date_utils";
import {
  addBookmarks,
  deleteBookmarks,
  fetchBookmarksFromApi,
  fetchDocumentsByIds, fetchUsersWatchlistBookmarks
} from "../../../../functions/fetching";
import InfoOverlayComponent from "../../common/InfoOverlayComponent.vue";
import Vue from "vue";
import i18n from "../../plugins/Translations.vue";
import SearchProfileMonitoringForm from "../../Monitoring/SearchProfileMonitoringForm.vue";
import eventBus from "../../../../eventBus";

export default {
  name: "ActionBar",
  components: {
    InfoOverlayComponent,
    SendEmailComponent,
    Button
  },
  data() {
    return {
      buttons: [
        { name: 'eye', icon: 'eye-outline', clickFunction: this.openDocuments },
        { name: 'open', icon: 'search-outline', clickFunction: this.openLink },
        { name: 'download', icon: 'download-outline', clickFunction: this.downloadDocuments },
        { name: 'mail', icon: 'mail-outline', clickFunction: this.sendEmailWithDocuments },
        { name: 'print', icon: 'print-outline', clickFunction: this.printDocuments },
        { name: 'move', icon: 'move', clickFunction: this.moveBookmarksToBookmarkList },
        { name: 'bookmark', icon: 'bookmark-outline', clickFunction: this.bookmarkDocuments },
        { name: 'remove-bookmark', icon: 'trash-outline', clickFunction: this.deleteBookmarksFromList},
        { name: 'remove', icon: 'trash-outline', clickFunction: this.deleteDocumentsFromList},
        { name: 'remove-history', icon: 'trash-outline', clickFunction: this.deleteSearchHistoryFromList},
        { name: 'remove-profiles', icon: 'trash-outline', clickFunction: this.deleteSearchProfileFromList},
        { name: 'edit-profiles', icon: 'create-outline', clickFunction: this.editSearchProfile},
        { name: 'toggle-monitoring', icon: 'alarm', clickFunction: this.toggleSearchProfileNotification},
        { name: 'create-monitoring', icon: 'alarm-outline', clickFunction: this.createSearchMonitoringFromSearchHistory},
        { name: 'clear', icon: 'trash-outline', clickFunction: () => this.showInfoOverlay('clearConfirmation') },
        { name: 'edit', icon: 'create-outline', clickFunction: () => this.showInfoOverlay('editConfirmation') },
        { name: 'delete-list', icon: 'close-circle-outline', clickFunction: () => this.showInfoOverlay('deleteConfirmation') },
        { name: 'mail-list', icon: 'mail-outline', clickFunction: this.sendBookmarkListByEmail },
        { name: 'download-list', icon: 'download-outline', clickFunction: this.downloadBookmarkList },
      ],
      expandedSendEmail: false,
      showDeleteDialog: false,
      sharedState: window.sharedState,
      showDeleteConfirmation: false,
      selectedListToDelete: null,
      modalContent: {},
      modalType: '',
      showInfoOverlayComponent: false,
    };
  },
  props: {
    actionBarButtons: Array,
    isItemAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedList: Array,
    allDocumentsOnPage: {
      type: Array,
      required: false,
      default: null
    },
    inputInitialValue: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    sortedVisibleButtons() {
      return this.buttons
        .filter(button => this.actionBarButtons.includes(button.name))
        .sort((a, b) => {
          return this.actionBarButtons.indexOf(a.name) - this.actionBarButtons.indexOf(b.name);
        })
        .map((item) => {
          if (item.name === 'toggle-monitoring') {
            return {
              ...item,
              icon: this.selectedList?.length === 1 && this.selectedList[0].active ? 'alarm' : 'alarm-outline'
            }
          }
          return item;
        })
    },
    count() {
      return this.selectedList.length;
    }
  },
  methods: {
    openDocuments() {
      openConfirmPurchaseOverlay(this.selectedList);
    },
    openLink() {
      window.location.href = this.selectedList.map(item => item.analysis?.url)?.[0];
    },
    downloadDocuments() {
      downloadCSV(this.selectedList);
    },
    async downloadBookmarkList() {
      const storedStoring = this.$store.getters['getUserInteractionBookmarksResultSettings'].sortAndOrder;
      const sort = storedStoring.sort;
      const order = storedStoring.order;
      const id = String(this.selectedList[0].id);

      await fetchBookmarksFromApi({ id, offset: 0, size: 100, sort, order })
          .then(async result => {
            const bookmarkedItemsArray = result.content.map(item => item.bookmarkedItem);
            const res = await fetchDocumentsByIds(bookmarkedItemsArray)
            downloadCSV(res.uniqueData)
          })
          .catch(error => {
            console.error("Error fetching bookmarks:", error);
          });
    },
    getDocumentToAdd() {
      return this.selectedList.map(item => {
        const docToAdd = {
          bookmarkedItem: item.documentId,
        }
        if (item.displayRow && item.displayRow.field_H) {
          return {
            ...docToAdd,
            itemDate: convertToZulu(item.displayRow.field_H)
          }
        }
        return docToAdd;
      });
    },
    async sendBookmarkListByEmail () {
      const {loggedIn} = await checkLogin();
      if (loggedIn) {
        openSendEmailOverlay('bookmark-list', [], this.getDocumentToSend(), this.selectedList[0]?.id);
      }  else {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#embedded_login", "genios.login.reasons.login", function () {
          closeTransparentPopupModal();
          setTimeout(function () {
            openSendEmailOverlay('bookmark-list', [], this.getDocumentToSend(), this.selectedList[0]?.id);
          }, 600);
        });
      }
    },
    async sendEmailWithDocuments() {
      const {loggedIn} = await checkLogin();
      if (loggedIn) {
        openSendEmailOverlay('general', [], this.getDocumentToSend());
      } else {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#embedded_login", "genios.login.reasons.login", function () {
          closeTransparentPopupModal();
          setTimeout(function () {
            openSendEmailOverlay('general', [], this.getDocumentToSend());
          }, 600);
        });
      }
    },
    printDocuments() {
      window.print();
    },
    moveBookmarksToBookmarkList() {
      const bookmarkLists = this.$store.getters.getBookmarkList;
      if (bookmarkLists.length === 1) {
        this.modalContent = {
          title: this.$t(`meinGenios.bookmarks.move.title`),
          description: this.$t(`meinGenios.bookmarks.move.emptyList`),
          secondaryButtonText: this.$t(`meinGenios.bookmarks.move.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
      } else if (this.selectedList && this.selectedList.length > 0) {
        const selectedBookmarkList = this.$store.getters.getSelectedBookmarkList;
        const dropdownElements = bookmarkLists.filter(bookmark => bookmark.id !== selectedBookmarkList.id);
        this.modalContent = {
          title: this.$t(`meinGenios.bookmarks.move.title`),
          description: this.count > 1 ? this.$t(`meinGenios.bookmarks.move.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.bookmarks.move.description`, {count: this.count}),
          primaryButtonText: this.$t(`meinGenios.bookmarks.move.confirm`),
          secondaryButtonText: this.$t(`meinGenios.bookmarks.move.cancel`),
          dropdownElements: dropdownElements.map(bookmark => {
            return {
              text: bookmark.name,
              value: bookmark.id,
            }
          }),
          primaryAction: this.confirmMoveBookmarks,
          secondaryAction: this.cancelTransparentOverlay,
        };
        this.modalType = 'dropdown'
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.bookmarks.move.title`),
          description: this.$t(`meinGenios.bookmarks.move.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.bookmarks.move.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
      }
      openOverlay(this.modalType, this.modalContent, this.inputInitialValue);
    },
    confirmMoveBookmarks(newBookmarkList) {
      this.moveBookmarks(newBookmarkList);
      this.cancelTransparentOverlay();
    },
    async moveBookmarks(newBookmarkList) {
      const ids = this.selectedList.map(item => item.bookmarkId);
      const docsToAdd = this.selectedList.map(doc => {
        const docToAdd = {
          bookmarkedItem: doc.documentId,
        }
        if (doc.itemDate) {
          return {
            ...docToAdd,
            itemDate:convertToZulu(doc.itemDate)
          }
        }
        return docToAdd;
      });
      if (ids.length > 0) {
        deleteBookmarks(ids).then(async () => {
          await addBookmarks([newBookmarkList.value], docsToAdd);
          if (this.isItemAction) {
            eventBus.$emit("update-view", ids);
          } else {
            this.$store.commit('bookmarksModule/setLoading', true);
            this.$store.commit('bookmarksModule/setBookmarks', []);
            eventBus.$emit("update-list");
          }
          await this.$store.dispatch('fetchBookmarkList');
        }).catch(errors => {
          this.$store.commit('bookmarksModule/setLoading', false);
          this.errorCode = "genios.watchlist.errorDelete";
          console.error("Move-Watchlist-Bookmarks " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
        });
      } else {
        console.log("No selected documents to move.");
      }
    },
    bookmarkDocuments() {
      if (window.sharedState.loginStatus.loggedIn) {
        openWatchlistAddOverlay(this.getDocumentToAdd());
      }
    },
    deleteBookmarksFromList() {
      openWatchlistDeleteOverlay(this.selectedList.map(item => item.bookmarkId), this.selectedList.map(item => item.documentTitle).slice(0, 5).join(' ') + ' ...', true, !this.isItemAction);
    },
    deleteDocumentsFromList() {
      if (this.selectedList && this.selectedList.length > 0) {
        this.modalContent = {
          title: this.$t(`meinGenios.documentHistory.delete.title`),
          description: this.count > 1 ? this.$t(`meinGenios.documentHistory.delete.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.documentHistory.delete.description`, {count: this.count}),
          primaryButtonText: this.$t(`meinGenios.documentHistory.delete.confirm`),
          secondaryButtonText: this.$t(`meinGenios.documentHistory.delete.cancel`),
          primaryAction: this.confirmDeleteDocuments,
          secondaryAction: this.cancelTransparentOverlay,
        };
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.documentHistory.delete.title`),
          description: this.$t(`meinGenios.documentHistory.delete.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.documentHistory.delete.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
      }
      openOverlay(this.modalType, this.modalContent, this.inputInitialValue);
    },
    deleteSearchHistoryFromList() {
      if (this.selectedList && this.selectedList.length > 0) {
        this.modalContent = {
          title: this.$t(`meinGenios.searchHistory.delete.title`),
          description: this.count > 1 ? this.$t(`meinGenios.searchHistory.delete.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.searchHistory.delete.description`, {count: this.count}),
          primaryButtonText: this.$t(`meinGenios.searchHistory.delete.confirm`),
          secondaryButtonText: this.$t(`meinGenios.searchHistory.delete.cancel`),
          primaryAction: this.confirmDeleteSearchHistory,
          secondaryAction: this.cancelTransparentOverlay,
        };
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.searchHistory.delete.title`),
          description: this.$t(`meinGenios.searchHistory.delete.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.searchHistory.delete.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
      }
      openOverlay(this.modalType, this.modalContent, this.inputInitialValue);
    },
    deleteSearchProfileFromList() {
      if (this.selectedList && this.selectedList.length > 0) {
        this.modalContent = {
          title: this.$t(`meinGenios.searchProfile.delete.title`),
          description: this.count > 1 ? this.$t(`meinGenios.searchProfile.delete.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.searchProfile.delete.description`, {count: this.count}),
          primaryButtonText: this.$t(`meinGenios.searchProfile.delete.confirm`),
          secondaryButtonText: this.$t(`meinGenios.searchProfile.delete.cancel`),
          primaryAction: this.confirmDeleteSearchProfiles,
          secondaryAction: this.cancelTransparentOverlay,
        };
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.searchProfile.delete.title`),
          description: this.$t(`meinGenios.searchProfile.delete.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.searchProfile.delete.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
      }
      openOverlay(this.modalType, this.modalContent, this.inputInitialValue);
    },
    editSearchProfile() {
      if (this.selectedList && this.selectedList.length > 0) {
        const createMonitoringForm = () => {
          const MonitoringFormClass = Vue.extend(SearchProfileMonitoringForm);
          const monitoringFormInstance = new MonitoringFormClass({
            i18n,
            propsData: {
              sharedState: {
                ...window.sharedState,
              },
              savedSearchProfile: this.selectedList[0]
            }
          });
          const overlayContent = document.querySelector("#overlay_content");
          overlayContent.innerHTML = '<div id="spmf"></div>';
          const div = document.querySelector("#spmf");
          monitoringFormInstance.$mount(div);
        };
        openTransparentPopupModal(createMonitoringForm);
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.searchProfile.delete.title`),
          description: this.$t(`meinGenios.searchProfile.delete.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.searchProfile.delete.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
        openOverlay(this.modalType, this.modalContent, this.inputInitialValue);
      }
    },
    async toggleSearchProfileNotification() {
      if (this.selectedList && this.selectedList.length > 0) {
        const searchProfile = {
          ...this.selectedList[0],
          active: !this.selectedList[0].active,
        };
        await this.$store.dispatch('searchProfileModule/updateSearchProfile', searchProfile);
      }
    },
    async createSearchMonitoringFromSearchHistory() {
      if (this.selectedList && this.selectedList.length > 0) {
        const createMonitoringForm = () => {
          const MonitoringFormClass = Vue.extend(SearchProfileMonitoringForm);
        const searchMonitoring = this.selectedList[0];
          const monitoringFormInstance = new MonitoringFormClass({
            i18n,
            propsData: {
              sharedState: {
                ...window.sharedState,
              },
              savedSearchProfile: {
                ...searchMonitoring,
                query: searchMonitoring?.analysis?.query || '',
              },
              createProfile: true
              }
          });
          const overlayContent = document.querySelector("#overlay_content");
          overlayContent.innerHTML = '<div id="mf"></div>';
          const div = document.querySelector("#mf");
          monitoringFormInstance.$mount(div);
        };
        openTransparentPopupModal(createMonitoringForm);
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.searchProfile.delete.title`),
          description: this.$t(`meinGenios.searchProfile.delete.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.searchProfile.delete.cancel`),
          primaryAction: this.cancelTransparentOverlay,
          secondaryAction: this.cancelTransparentOverlay,
        };
        openOverlay(this.modalType, this.modalContent, this.inputInitialValue);
      }
    },
    showInfoOverlay(type) {
      let primaryAction;
      if (type === 'deleteConfirmation') {
        const hasStandardMerkliste = this.selectedList.some(item => item.name === "Standard-Merkliste");
        if (hasStandardMerkliste) {
          this.$emit('standard-merkliste-selected');
          return;
        }
        primaryAction = this.confirmDeleteBookmarkList;
      } else if (type === 'clearConfirmation') {
        primaryAction = this.confirmClearBookmarkList;
      } else if (type === 'editConfirmation') {
        primaryAction = this.confirmEditBookmarkList;
      }

      this.modalContent = {
        title: this.$t(`genios.bookmarkList.${type}.title`),
        description: type !== 'editConfirmation' ? this.$t(`genios.bookmarkList.${type}.description`) : null,
        primaryButtonText: this.$t(`genios.bookmarkList.${type}.confirm`),
        secondaryButtonText: this.$t(`genios.bookmarkList.${type}.cancel`),
        inputPlaceholder: this.$t(`genios.bookmarkList.${type}.description`),
        primaryAction: primaryAction,
        secondaryAction: this.cancelInfoOverlay,
      };

      this.showInfoOverlayComponent = true;

      this.modalType = type === 'editConfirmation' ? 'input' : '';
    },
    cancelInfoOverlay() {
      this.showInfoOverlayComponent = false;
    },
    cancelTransparentOverlay() {
      closeTransparentPopupModal();
    },
    confirmDeleteBookmarkList() {
      this.deleteBookmarkList();
      eventBus.$emit('update-list');
      this.showInfoOverlayComponent = false;
    },
    confirmClearBookmarkList() {
      this.clearBookmarkList();
      this.showInfoOverlayComponent = false;
    },
    confirmEditBookmarkList(newName) {
      if (this.selectedList.length === 1) {
        const id = this.selectedList[0].id;
        this.editBookmarkList(id, newName);
      } else {
        console.log("No list selected or multiple lists selected.");
      }
      this.showInfoOverlayComponent = false;
    },
    confirmDeleteSearchHistory() {
      this.deleteSearchHistory();
      closeTransparentPopupModal();
    },
    async deleteSearchHistory() {
      const ids = this.selectedList.map(item => item.id);
      if (ids.length > 0) {
        await this.$store.dispatch('searchHistoryModule/deleteSearchHistory', ids);
        this.$emit('update-list');
      } else {
        console.log("No selected search to delete.");
      }
    },
    confirmDeleteSearchProfiles() {
      this.deleteSearchProfiles();
      closeTransparentPopupModal();
    },
    async deleteSearchProfiles() {
      const ids = this.selectedList.map(item => item.profileId);
      if (ids.length > 0) {
        await this.$store.dispatch('searchProfileModule/deleteSearchProfiles', ids);
        this.$emit('update-list');
      } else {
        console.log("No selected search to delete.");
      }
    },
    confirmDeleteDocuments() {
      this.deleteDocuments();
      this.cancelTransparentOverlay();
    },
    async deleteDocuments() {
      const ids = this.selectedList.map(item => item.bookmarkId);
      if (ids.length > 0) {
        await this.$store.dispatch('documentHistoryModule/deleteDocumentHistory', ids);
        this.$emit('update-list');
      } else {
        console.log("No selected documents to delete.");
      }
    },
    async deleteBookmarkList() {
      const ids = this.selectedList.map(item => item.id);
      if (ids.length > 0) {
        await this.$store.dispatch('deleteBookmarkList', ids);
      } else {
        console.log("No selected bookmark lists to delete.");
      }
    },
    async clearBookmarkList() {
      const ids = this.selectedList.map(item => item.id);
      if (ids.length > 0) {
        await this.$store.dispatch('clearBookmarkList', ids);
        window.sharedState.watchlistDocumentIds = null;
        sessionStorage.setItem('watchlistDocumentIds', null);
        fetchUsersWatchlistBookmarks();
        const selectedBookmarkList = this.$store.getters['getSelectedBookmarkList'] || '';
        if (ids.includes(selectedBookmarkList.id)) {
          await this.$store.dispatch('bookmarksModule/fetchBookmarks',
              selectedBookmarkList.id );
        }
      } else {
        console.log("No selected bookmark lists to clear.");
      }
    },
    async editBookmarkList(id, name) {
      if (name) {
        await this.$store.dispatch('updateBookmarkList', {id: id, name: name});
      } else {
        console.log("No name given to update.");
      }
    },
    getDocumentToSend() {
      if (this.selectedList.length > 0) {
        return this.selectedList.map(item => {
          return {
            documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
          }
        });
      }
      return this.allDocumentsOnPage?.length ? this.allDocumentsOnPage.map(item => {
        return {
          documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
        };
      }) : [];
    },
  }
}
</script>