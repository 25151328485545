import OverlayScrollbars from "overlayscrollbars";
import {
    fetchAndInsertDocumentInPreview,
    fetchHigherResultListDocumentIds,
    fetchLowerResultListDocumentIds,
    fetchRemoteAgencyDocument
} from "../fetching";
import {closeAllPreviews} from "../closing";
import {checkFullPopupModalPosition, checkShowMoreShowLessSections} from "../checks";
import _, {isUndefined} from "lodash";
import {addLoader, styleActionBarPosition} from "../stylization";
import updatePrices from "../updatePrices";
import {openFullArticleModal, openPreview, openSendEmailOverlay} from "../opens";
import {changeHistory} from "../utils/history_utils";
import {replaceBrowseResult} from "../replacing";
import browser from "browser-detect";
import eventBus from "../../eventBus";
import {getVideoData} from "../getters";
import {
    closeAllMySourcesPopups,
    JS_HANDLER,
    setServerIconState,
    setupServerOutlineIcons,
    sourceIconState
} from "../components/mySourceComponent";

export const setupBrowsePagingFunctionality = function () {
    const browsePage = document.querySelector(".browse_page");
    if (browsePage) {
        const pagingLinks = browsePage.querySelectorAll('[js-handler*="pagingClickHandler"]');

        pagingLinks.forEach(link => {
            const offset = link.getAttribute("offset");
            const pagingClickHandler = function (e) {
                e.preventDefault();
                // e.stopPropagation();
                if (!!window.pageOverlayScrollbar) window.pageOverlayScrollbar.scroll(0);
                window.sharedState.offset = offset;
                changeHistory({offset: offset, preview: null}, true);
                replaceBrowseResult();
            };
            link.removeEventListener('click', pagingClickHandler);
            link.addEventListener('click', pagingClickHandler);
        });
    }

};


export const setupRemoteAgencyDocDownloadButton = function (elementToScan) {
    elementToScan = elementToScan || document.body;
    const downloadRemoteAgencyDocButton = elementToScan.querySelectorAll('[js-handler*="download_remoteAgency_attachment"],.js_download_remoteAgency_attachment');
    const handleRemoteAgencyClick = function (e) {
        e.preventDefault();
        fetchRemoteAgencyDocument(this);
    };
    downloadRemoteAgencyDocButton.forEach(button => {
        const isPayed = button.querySelector('.priceIndicator')?.classList.contains('paid');
        if (isPayed) {
            button.classList.replace("button__primary", "button__secondary");
        }
        button.removeEventListener('click', handleRemoteAgencyClick);
        button.addEventListener('click', handleRemoteAgencyClick);
    });
};

export const setupFullDocumentFunctionality = function (domElement = document) {
    if (!domElement) domElement = document;
    const documentLinks = domElement.querySelectorAll('.js_open_full_document_modal') || null;

    const openArticleOnClick = async function (e) {
        e.preventDefault();
        window.fetchHigherDocumentIds = fetchHigherResultListDocumentIds;
        window.fetchLowerDocumentIds = fetchLowerResultListDocumentIds;
        window.sharedState.documentListOffset = window.sharedState.offset;
        window.sharedState.fullArticleModalList = getDocumentListFromPage(domElement);

        const docId = e.currentTarget.getAttribute("data-document-id");
        const linkType = !!e.target.dataset.linkType;
        if (linkType)
            await addLoader(e, "link", async () => {
                await openFullArticleModal(docId);
            });
        else {
            await openFullArticleModal(docId);
        }
    };
    documentLinks.forEach(link => {
        link.removeEventListener('click', openArticleOnClick);
        link.addEventListener('click', openArticleOnClick);
    });

    const previewBuyButtons = domElement.querySelectorAll('.js_preview_buy_click_handler');
    const buyArticleFromPreview = async function (e) {
        e.preventDefault();
        changeHistory({bundleDocumentId: undefined, expandedListName: undefined});
        let documentId = e.currentTarget.getAttribute("data-document-id");
        if (!!documentId) {
            await addLoader(e, "link", async () => {
                await fetchAndInsertDocumentInPreview(documentId, null, null, {mediaType: e.target.dataset.mediaType});

                if (documentId.split('__')[0] === 'FIDO') {
                    e.target.parentNode.classList.add('hide');
                }
            });

        }
    };

    previewBuyButtons.forEach(link => {
        link.removeEventListener('click', buyArticleFromPreview);
        link.addEventListener('click', buyArticleFromPreview);
    });

    function getDocumentListFromPage(domElement) {
        const articles = domElement.querySelectorAll('article');

        function getIdFromArticleElement(articleIdElement) {
            return articleIdElement.substr(6);
        }

        let documentIdList = [];
        if (!!articles) {
            documentIdList = Array.from(articles).map(element => getIdFromArticleElement(element.getAttribute("id")));
        }
        return documentIdList;
    }
};

export const setupHeaderNavigationButton = function () {
    const navButtons = document.querySelectorAll('[js-handler*="headerNavigationButtonClickHandler"]');
    navButtons.forEach(button => {
        button.removeEventListener('click', headerNavigationButtonClickHandler);
        button.addEventListener('click', headerNavigationButtonClickHandler);

        function headerNavigationButtonClickHandler() {
            const myNavModal = document.querySelector(".myNavOverlay");
            const myNav = document.querySelector('.myNav');
            const blocker = myNav.querySelector(".blocker");
            myNavModal.style.maxWidth = "385px";
            blocker.classList.remove('hide');
        }
    });
};

export const setupMiniPopup = function (popupContainerElement) {
    const popupDivs = popupContainerElement.querySelectorAll('[js-handler*="miniPopup"]');

    let pos1 = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;

    let openPopupElement = null;
    let miniPopupOverlayScrollbar = null;

    const dragMouseDown = function (e) {
        e.preventDefault();
        e.stopPropagation();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.removeEventListener('mouseup', closeDragElement);
        document.addEventListener('mouseup', closeDragElement);
        document.removeEventListener('touchend', closeDragElement, {passive: false});
        document.addEventListener('touchend', closeDragElement, {passive: false});
        // call a function whenever the cursor moves:
        document.removeEventListener('mousemove', elementDrag);
        document.addEventListener('mousemove', elementDrag);
        document.removeEventListener('touchmove', elementDrag, {passive: false});
        document.addEventListener('touchmove', elementDrag, {passive: false});
    };

    const elementDrag = function (e) {
        e.preventDefault();
        // calculate the new cursor position:
        let newX;
        let newY;
        const touch = (!!e.touches) ? (e.touches[0] || e.changedTouches[0]) : false;
        if (!!touch) {
            newX = touch.pageX;
            newY = touch.pageY;
        } else {
            newX = e.clientX;
            newY = e.clientY;
        }

        pos1 = pos3 - newX;
        pos2 = pos4 - newY;
        pos3 = newX;
        pos4 = newY;
        // set the element's new position:
        const elmnt = openPopupElement;
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        const popupTextContentText = elmnt.querySelector('.popuptext-content__text');
        if (!!popupTextContentText) {
            setMaxHeight(popupTextContentText);
        }
    };

    const closeDragElement = function () {
        /* stop moving when mouse button is released:*/
        document.removeEventListener('mouseup', closeDragElement);
        document.removeEventListener('touchend', closeDragElement, {passive: false});
        document.removeEventListener('mousemove', elementDrag);
        document.removeEventListener('touchmove', elementDrag, {passive: false});
    };

    const setMaxHeight = function (popupTextContentText) {
        const position2 = popupTextContentText.getBoundingClientRect();
        popupTextContentText.style.maxHeight = (document.documentElement.clientHeight - position2.top) - 20 + 'px';
        miniPopupOverlayScrollbar = OverlayScrollbars(popupTextContentText, {
            //sizeAutoCapable: false,
            autoUpdateInterval: 10,
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });
    };

    const closePopup = function (popupText) {
        popupText.classList.remove("active");
        popupText.style = "";
        if (!!miniPopupOverlayScrollbar) {
            miniPopupOverlayScrollbar.destroy();
            miniPopupOverlayScrollbar = null;
        }
        openPopupElement = null;
        const popupTextContentTitle = popupText.querySelector('.popuptext-content__title');
        if (!!popupTextContentTitle) {
            popupTextContentTitle.removeEventListener("touchstart", dragMouseDown, {passive: false});
        }
    };

    const toggleMiniPopup = function (event) {
        const popupDiv = event.currentTarget;
        const popupText = popupDiv.querySelector('.popuptext');
        const popupTextContentTitle = popupText.querySelector('.popuptext-content__title');
        const popupTextContentText = popupText.querySelector('.popuptext-content__text');
        if (popupText) {
            if (popupText.classList.contains("active")) {
                closePopup(popupText);
            } else {
                if (!!openPopupElement) {
                    closePopup(openPopupElement); //we don't want more than one open at a time
                }
                popupText.classList.add("active");
                const width = popupText.offsetWidth;
                const position = popupText.getBoundingClientRect();
                popupText.style.width = width + 'px';
                popupText.style.position = "fixed";
                popupText.style.top = position.top + 'px';
                popupText.style.left = position.left + 'px';
                if (!!popupTextContentTitle) {
                    popupTextContentTitle.removeEventListener("mousedown", dragMouseDown);
                    popupTextContentTitle.addEventListener("mousedown", dragMouseDown);
                    popupTextContentTitle.removeEventListener("touchstart", dragMouseDown, {passive: false});
                    popupTextContentTitle.addEventListener("touchstart", dragMouseDown, {passive: false});
                    popupTextContentTitle.onclick = function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                    };
                    openPopupElement = popupText;
                }
                if (!!popupTextContentText) {
                    setMaxHeight(popupTextContentText);
                }
            }
        }
    };

    popupDivs.forEach(popupDiv => {
        if (typeof (popupDiv) !== 'undefined' && popupDiv !== null) {
            let popupDivClone = popupDiv.cloneNode(true);
            popupDiv.parentNode.replaceChild(popupDivClone, popupDiv);

            popupDivClone.removeEventListener('click', toggleMiniPopup);
            popupDivClone.addEventListener('click', toggleMiniPopup);
        }
    });
};

export const setupPreviewFunctionality = function () {

    const concatTiles = function (event) {
        event.preventDefault();
        const clickedLink = event.target;
        const parentNode = clickedLink.parentNode;
        const olderIssueLink = clickedLink.href;
        clickedLink.style = "pointer-events: none"; // prevent further clicking
        clickedLink.removeEventListener('click', concatTiles);
        clickedLink.outerHTML = "<div class='loading' style='width:100%'><div class='loader'></div></div>";
        const loader = parentNode.querySelector(".loader");
        fetch(olderIssueLink).then(function (response) {
            return response.text();
        }).then(function (html) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const newTileSectionsContainer = doc.querySelector("#partialSourceListContainer");
            const newPreviewSectionsContainer = doc.querySelector("#previewDiv");
            const oldTileSectionsContainer = document.querySelector("#partialSourceListContainer");
            const oldPreviewSectionsContainer = document.querySelector("#previewDiv");
            while (newTileSectionsContainer.childNodes.length) {
                const childNode = newTileSectionsContainer.childNodes[0];
                if (childNode.classList && childNode.classList.contains("partialSourceListArea")) {
                    childNode.classList.add("partialSourceListArea-new");
                }
                oldTileSectionsContainer.appendChild(childNode);
            }
            while (newPreviewSectionsContainer.childNodes.length) {
                oldPreviewSectionsContainer.appendChild(newPreviewSectionsContainer.childNodes[0]);
            }
            const newLink = doc.querySelector(".archive__older_link");
            if (!!newLink) {
                oldTileSectionsContainer.parentNode.appendChild(newLink);
            }
            loader.remove();
            setupPreviewFunctionality();
        }).catch(function (err) {
            // There was an error
            console.warn('Something went wrong getting older issues.', err);
        });
    };

    const gridViewContainerElem = document.querySelector('[js-handler*="containerClickHandler"]');
    if (typeof (gridViewContainerElem) !== 'undefined' && gridViewContainerElem !== null) {
        const sourceTiles = document.querySelectorAll('[js-handler*="tileClickHandler"]');
        sourceTiles.forEach(tile => {
            const tileClickHandler = function (e) {
                e.stopPropagation();
                //TODO: think about proper solution
                if (!e.target.closest(".my-sources")) {
                    closeAllMySourcesPopups()
                    openPreview(tile.getAttribute('docid'));
                }
            };
            tile.removeEventListener('click', tileClickHandler);
            tile.addEventListener('click', tileClickHandler);
        });

        const mediaPreviewElems = document.querySelectorAll('.media_preview');
        mediaPreviewElems.forEach((elem, index) => {
            const leftButton = elem.querySelector('[js-handler*="openPrevious"]');
            const rightButton = elem.querySelector('[js-handler*="openNext"]');
            const closeButton = elem.querySelector('[js-handler*="closePreviews"]');
            if (typeof (rightButton) !== 'undefined' && rightButton !== null) {
                if (index < (mediaPreviewElems.length - 1)) {
                    const openNext = function () {
                        changeHistory({bundleDocumentId: undefined, expandedListName: undefined});
                        eventBus.$emit("destroyFIDO");
                        closeAllPreviews();
                        openPreview(mediaPreviewElems[index + 1].id);
                    };
                    let rightButtonClone = rightButton.cloneNode(true);
                    rightButton.parentNode.replaceChild(rightButtonClone, rightButton);
                    rightButtonClone.removeEventListener('click', openNext);
                    rightButtonClone.addEventListener('click', openNext);
                } else {
                    const archiveOlderLink = document.querySelector('[js-handle*="archiveOlderLinkHandler"]');
                    if (!!archiveOlderLink) {
                        let rightButtonClone = rightButton.cloneNode(true);
                        rightButton.parentNode.replaceChild(rightButtonClone, rightButton);
                        rightButtonClone.addEventListener('click', function () {
                            archiveOlderLink.click();
                        });
                    } else {
                        rightButton.remove();
                    }
                }
            }
            if (typeof (leftButton) !== 'undefined' && leftButton !== null) {
                if (index > 0) {
                    const openPrevious = function () {
                        changeHistory({bundleDocumentId: undefined, expandedListName: undefined});
                        eventBus.$emit("destroyFIDO");
                        closeAllPreviews();
                        openPreview(mediaPreviewElems[index - 1].id);
                    };
                    let leftButtonClone = leftButton.cloneNode(true);
                    leftButton.parentNode.replaceChild(leftButtonClone, leftButton);
                    leftButtonClone.removeEventListener('click', openPrevious);
                    leftButtonClone.addEventListener('click', openPrevious);
                } else {
                    leftButton.remove();
                }
            }
            if (typeof (closeButton) !== 'undefined' && closeButton !== null) {
                const closePreviews = function () {
                    changeHistory({preview: "", bundleDocumentId: undefined, expandedListName: undefined}, true);
                    eventBus.$emit("destroyFIDO");
                    closeAllPreviews(true);
                };
                let closeButtonClone = closeButton.cloneNode(true);
                closeButton.parentNode.replaceChild(closeButtonClone, closeButton);
                closeButtonClone.removeEventListener('click', closePreviews);
                closeButtonClone.addEventListener('click', closePreviews);
            }
            setupMiniPopup(elem);
        });
    }
    const archiveOlderLink = document.querySelector('[js-handler*="archiveOlderLinkHandler"]');
    if (!!archiveOlderLink) {
        archiveOlderLink.removeEventListener('click', concatTiles);
        archiveOlderLink.addEventListener('click', concatTiles);
    }
};

export const setupResizeHandler = async function () {

    const _debounce = function (ms, fn) {
        let timer;
        return function () {
            clearTimeout(timer);
            const args = Array.prototype.slice.call(arguments);
            args.unshift(this);
            timer = setTimeout(fn.bind.apply(fn, args), ms);
        };
    };

    const ro = new ResizeObserver(_debounce(500, function () {
        checkShowMoreShowLessSections();
        checkFullPopupModalPosition();
        document.querySelectorAll("iframe.home__corporate_content").forEach(iframe => {
            iframe.style.height = (iframe.contentWindow.document.body?.scrollHeight || 0) + 'px';
        });
    }));

    ro.observe(document.querySelector('.content'));
};

export const setupPreview = function (previewElemToOpen, docId) {
    const existingPreviewPanelOverlayScrollbar = window.previewPanelOverlayScrollbar;
    if (browser().mobile) {
        if (!previewElemToOpen.classList.contains('full_preview') && window.innerWidth < 600 && window.innerWidth > 1000) {
            const fullHeight = previewElemToOpen.querySelector('#' + docId + '__content').getBoundingClientRect().height;
            previewElemToOpen.setAttribute("style", "height:" + (fullHeight + 25) + "px");
        }

    }

    window.previewPanelOverlayScrollbar = OverlayScrollbars(previewElemToOpen, {
        scrollbars: {
            autoHide: "move",
            clickScrolling: true
        }
    });

    const mediaTiles = document.querySelectorAll('.media_tile');
    const mediaTile = Array.from(mediaTiles)
        .find(tile => tile.getAttribute('docid') === docId) || null;

    if (!!mediaTile) {
        changeHistory({bundleDocumentId: undefined, expandedListName: undefined});
        mediaTile.classList.add("opened");
        // mark button as visited
        // const button = mediaTile.querySelector(".js_tile_preview");
        const button = mediaTile.querySelector('[js-handler*="jsTilePreview"]');

        if (!!button) button.classList.replace("button__primary", "button__secondary");
    }

    let gridViewContainerElem = document.querySelector('.browse_page');
    if (!gridViewContainerElem) {
        gridViewContainerElem = document.querySelector('.result-div');
    }
    if (!browser().mobile) {
        gridViewContainerElem.classList.add("own_scroll");
        gridViewContainerElem.classList.add("os-host-flexbox");
    }

    if (!browser().mobile && !window.tileOverlayScrollbar) {

        window.tileOverlayScrollbar = OverlayScrollbars(gridViewContainerElem, {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });

        let actionBarContainer = document.querySelector(".action_bar_container");
        if (!!actionBarContainer) {
            let stopPosition = (actionBarContainer.offsetTop - 10);
            window.tileOverlayScrollbar.options("callbacks.onScroll", _.debounce(() => {
                let scrollTop = window.tileOverlayScrollbar.scroll().position.y;
                let resultedTop;
                if (!!window.pageOverlayScrollbar) {
                    let pageOverlayTop = window.pageOverlayScrollbar.scroll().position.y;
                    resultedTop = pageOverlayTop + scrollTop;
                } else {
                    resultedTop = scrollTop;
                }
                styleActionBarPosition(resultedTop, stopPosition);
            }, 10));
        }

    }

    setTimeout(function () {
            window.pageOverlayScrollbar && window.pageOverlayScrollbar.scroll({y: "0%"}, 50, "easeOutBounce");
            existingPreviewPanelOverlayScrollbar && existingPreviewPanelOverlayScrollbar.scroll({y: "0%"}, 50, "easeOutBounce");

            if (!!window.tileOverlayScrollbar) {
                window.tileOverlayScrollbar.scroll({
                    el: mediaTile,
                    block: {y: "nearest", x: "never"},
                    scroll: ["never", "ifneeded"],
                    margin: [20, 0, 120, 0]
                }, 100, "easeOutBounce");
            }
            //mediaTile.scrollIntoView({block: "nearest", inline:"nearest"});
            if (!!existingPreviewPanelOverlayScrollbar) {
                existingPreviewPanelOverlayScrollbar.destroy();
            }
        },
        200
    );
    checkShowMoreShowLessSections(previewElemToOpen);
    updatePrices(previewElemToOpen, docId);
};

export const setupSendEmailFunctionality = (docBody, documentId) => {
    if (docBody) {
        const emailBtn = docBody.querySelector('#action_bar_marker button[js-handler="send_email"]') || null;
        const sendEmailHandler = () => {
            openSendEmailOverlay('general', [documentId]);
        };
        if (emailBtn) {
            emailBtn.removeEventListener('click', sendEmailHandler);
            emailBtn.addEventListener('click', sendEmailHandler);
        }
    }
};

export const setupVideoFrame = async (params = {}, body = {}) => {
    try {
        const {videoId} = params;
        if (!isUndefined(videoId) && !!videoId) {
            const data = await getVideoData(params, body);
            if (data?.code === "VIDEO_PAYMENT_SUCCESS") {
                const {videoModel: video} = data;
                eventBus.$emit("update-current-video", {...video, url: video.videoUrl});
                eventBus.$emit("update-video", {...video, url: video.videoUrl});
            }
        }
    } catch (e) {
        console.log("setupVideoFrame:error => ", e);
        console.dir(e);
    }
};

export const setupMySourcesIcon = async (sources = [], selectedItem = null) => {

    let isLoggedIn = window.sharedState.loginStatus?.loggedIn || false;
    let mySource = window.sharedState.mySources;

    if (isLoggedIn) {
        const mySources = _.uniq(_.flatten(mySource.map(item => item.subTags || [])
            .filter(item => item.length))
            .map(item => item.name));

        sources.forEach(item => {
            const tileName = item.querySelector(".media-article__text__field_B").innerText.trim();
            const iconBtn = item.querySelector(`[js-handler*="${JS_HANDLER}"]`) || null;

            const db = item.dataset.db
            const selectedItemTile = selectedItem?.closest(".media_tile.element")

            if (!!iconBtn && db !== selectedItemTile?.dataset.db) {
                const parentIcon = iconBtn.closest(".bookmark")
                if (db !== window.sharedState.currentMySources?.db) {
                    if (mySources.includes(tileName)) {
                        setServerIconState(sourceIconState.SERVER, iconBtn)
                        parentIcon.classList.add("bookmark-icon")
                    } else {
                        setServerIconState(sourceIconState.OUTLINE, iconBtn)
                        parentIcon.classList.remove("bookmark-icon")
                    }
                }
            }
        });

    } else {
        setupServerOutlineIcons(sources)
    }
};
