<template>
  <form
      v-if="modalData"
      class="modal-overlay"
      @submit.prevent="onPrimaryAction"
      @keydown.esc="onSecondaryAction">
    <div class="modal-wrapper">
      <div class="modal-container__close-icon" @click="onSecondaryAction">
        <ion-icon name="close-outline"></ion-icon>
      </div>
      <div class="modal-container">
        <div class="modal-container__content">
          <div v-if="modalData.title" class="modal-container__title">{{ modalData.title }}</div>
          <div v-if="type === 'input'" class="modal-container__input">
            <text-form-element
                ref="focusMe"
                :element="inputElement"
                :focus="focus"
                :passed-value="inputValue"
                @modified="inputValue = $event"
                @focus="focus = $event"
            ></text-form-element>
          </div>
          <div class="modal-container__content--container">
            <overlay-scrollbars
                :options="{
            scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
            overflowBehavior: { x: 'hidden'}
           }"
            >
              <div class="modal-container__content--list">
                <div v-if="modalData.description" class="modal-container__description" :class="{'standard-color': modalData.standardColor}">{{ modalData.description }}</div>
                <div v-if="$slots.default" class="searchResult__active_filters">
                  <slot></slot>
                </div>
              </div>
            </overlay-scrollbars>
          </div>
        </div>
        <div class="modal-container__divider"></div>
        <div class="modal-container__footer">
          <Button
              v-if="modalData.secondaryButtonText"
              :text="modalData.secondaryButtonText"
              variant="secondary"
              name="secondary"
              :toggle-text="false"
              type="button"
              @click="onSecondaryAction"
          />
          <Button
              v-if="modalData.primaryButtonText"
              :text="modalData.primaryButtonText"
              variant="primary"
              name="primary"
              :toggle-text="false"
              type="submit"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Button from "../../components/styled/Button.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import {OverlayScrollbarsComponent} from "overlayscrollbars-vue";

export default {
  components: {
    TextFormElement,
    Button,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    modalData: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: null,
    },
    initialInputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      inputValue: this.initialInputValue,
      focus: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.focusMe?.$el?.querySelector('input')?.focus();
    });
  },
  computed: {
    inputElement() {
      return {
        id: 'info_overlay_input',
        fieldType: 'text',
        fieldLabel: this.modalData?.inputPlaceholder || '',
        hideLabel: !this.modalData?.inputPlaceholder };
    },
  },
  methods: {
    onPrimaryAction() {
      if (this.type === "input") {
        this.$emit("primary-action", this.inputValue);
      } else {
        this.$emit("primary-action");
      }
    },
    onSecondaryAction() {
      this.$emit("secondary-action");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-block);
  z-index: 1001;
}

.modal-wrapper {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  max-height: 90vh;
}

.modal-container__close-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -50px;
  width: 40px;
  height: 40px;
  background-color: var(--background-color);
  border-radius: 50%;
  box-shadow: var(--active-shadow);
  cursor: pointer;
}

.modal-container {
  background-color: var(--background-color);
  box-shadow: var(--active-shadow);
  padding-top: 30px;
  border-radius: 20px;
  width: 560px;
  max-height: 90vh;
}

.modal-container__content {
  padding: 0 30px;
}

.modal-container__content--container {
  max-height: 80vh;
}

.modal-container__content--list {
  max-height: 70vh;
}

.modal-container__content--list:first-child {
  margin-bottom: 10px;
}

.modal-container__description {
  color: var(--shade-dark);
}

.modal-container__description.standard-color {
  color: var(--foreground-color);
}

.modal-container__footer {
  margin: 0 30px 30px;
  border-top: none;
  padding: 0;
}

.modal-container__divider {
  width: 100%;
  height: 1px;
  background: var(--shade-color);
  margin: 25px 0 30px;
}
</style>

