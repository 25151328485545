<template>
  <div :data-db-shortcut="dbShortcut">
    <div class="table_of_contents" v-show="latestArticles.length > 0">
      <div class="table_of_contents__header">
        <div class="table_of_contents__header__contents">
          <div v-if="'Audio' === itemType">
            {{$t('genios.preview.contents.audio.contents')}}
          </div>
          <div v-else>
            {{$t('genios.preview.contents.contents')}}
          </div>
          <div class="tooltip tooltip-icon" v-if="hasPersonalizationPermission">
            <button type="button"
                    id="press_notification_button"
                    class="button__secondary"
                    :data-shortcut="dbShortcut"
                    :data-source-name="sourceName"
                    @click="openPressNotification"
                    style="font-weight: 600;font-size: 18px;"
            >
            <ion-icon name="alarm-outline"></ion-icon>
            &nbsp;
            <span>{{$t('genios.monitoring.press.button')}}</span>
            </button>
            <div class="tooltiptext tooltiptext-button tooltip-top tooltip-top-icon">
              {{$t('genios.monitoring.press.tooltip')}}
            </div>
          </div>
        </div>
        <div class="table_of_contents__header__documents">
          <span v-if="'Audio' === itemType">{{ $t('genios.preview.contents.audio.from') + ': ' + latestIssueDate}} &nbsp;</span>
          <span v-else>{{ $t('genios.preview.contents.issue') + ': ' + latestIssueDate + ' - ' + filteredArticles.length}} &nbsp;</span>
          <template v-if="'Audio' !== itemType">
            <span v-if="filteredArticles.length === 1">{{$t('genios.preview.contents.document')}} </span>
            <span v-else>{{ $t('genios.preview.contents.documents')}}</span>
          </template>
        </div>

      </div>
      <div class="table_of_contents__body">
        <DropdownFormElement
            v-if="hasRegional"
            :element="regionalFilter"
            :focus="focus"
            :passed-value="initialFilter"
            @modified="updateShownArticles($event)"
            v-touch-hover
        />
        <div v-if="'Audio' !== itemType" class="table_of_contents__body__document">
          <div class="table_of_contents__body__document__header">{{ $t('genios.preview.contents.header.title') }}</div>
          <div class="table_of_contents__body__document__header">{{ $t('genios.preview.contents.header.pages') }}</div>
        </div>
        <div v-for="article in filteredArticles">
          <a class="table_of_contents__body__document hyperlink-toc"
             :href="`/document/${article.documentId}`"
             :data-document-id="article.documentId"
             :data-legacy-doc-id="article.legacyDocId"
             :data-price-category="article.priceCategory || ''"
             data-force-confirm-price=""
             @click="openArticleOnClick($event, article.documentId)"
             js-handler="updatePrices openArticleOnClick"
             :key="article.documentId"
             :download-display-name="removeHighlightingAndConvertSpecialCharacters(article.documentTitle)"
             v-touch-hover
          >
            <div class="table_of_contents__body__document__text document_title_wrapper">
              <div class="tooltip">
                <div class="table_of_contents__body__document__text document_title_wrapper__title-text">
                  {{ article.documentTitle }}
                </div>
                <div class="table_of_contents__body__document__text document_title_wrapper__regional-text" id="overflowCheckDiv">
                  {{ article.regional }}
                </div>
                <div class="tooltiptext tooltip-top tooltip-top-icon">
                  <div class="tooltip-title">
                    {{ article.documentTitle }}
                  </div>
                  <div class="tooltip-regional">
                    {{ article.regional }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="'Audio' !== itemType" class="table_of_contents__body__document__page">{{ article.page }}</div>
          </a>
        </div>
      </div>

    </div>
    <div v-show="latestArticles.length === 0" class="no_table_of_contents"></div>
  </div>
</template>

<script>

import {getTableOfContents} from "../../../functions/services";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import {openFullArticleModal, openTransparentPopupModal} from "../../../functions/opens";
import {
  setupLoginComponent,
  setupPaymentFormComponent,
  setupPressNotificationComponent
} from "../../../functions/setups/vue-components";
import axios from "axios";
import {PaymentMode} from "../../../functions/components/payment";
import {fakeVisitedLink} from "../../../functions/utils/history_utils";
import i18n from "../plugins/Translations.vue";
import updatePrices from "../../../functions/updatePrices";
import {hasPermission} from "../../../functions/utils/permission_utils";

export default {
  name: "TableOfContents",
  components: {DropdownFormElement},
  props: {
    sourceShortcut: String,
    issueId: String,
    itemType: String,
    useDate: String,
    sharedState: Object
  },
  directives: {
    'touch-hover': {
      bind(el) {
        el.addEventListener('touchstart', function() {
          const activeTooltips = document.querySelectorAll('.tooltip-active');
          const firstChild = el.firstChild;
          const tooltip = firstChild.firstChild;
          activeTooltips.forEach(elem => {
            if (elem !== tooltip) {
              elem.classList.remove('tooltip-active');
            }
          });
          const overflowElement = el.querySelectorAll('#overflowCheckDiv');
          const hasWrappedText = Array.from(overflowElement).some(div => div.scrollWidth > div.clientWidth);
          if (hasWrappedText) {
            tooltip.classList.contains('tooltip-active') ? tooltip.classList.remove('tooltip-active') : tooltip.classList.add('tooltip-active');
          }
        });
      },
      unbind(el) {
        el.removeEventListener('touchstart', function() {
          const activeTooltips = document.querySelectorAll('.tooltip-active');
          const firstChild = el.firstChild;
          const tooltip = firstChild.firstChild;
          activeTooltips.forEach(elem => {
            if (elem !== tooltip) {
              elem.classList.remove('tooltip-active');
            }
          });
          const overflowElement = el.querySelectorAll('#overflowCheckDiv');
          const hasWrappedText = Array.from(overflowElement).some(div => div.scrollWidth > div.clientWidth);
          if (hasWrappedText) {
            tooltip.classList.contains('tooltip-active') ? tooltip.classList.remove('tooltip-active') : tooltip.classList.add('tooltip-active');
          }
        });
      },
    }
  },
  data() {
    return {
      focus: null,
      latestArticles: [],
      filteredArticles: [],
      dbShortcut: "",
      sourceName: "",
      latestIssueDate: "",
      hasPersonalizationPermission: false,
      hasRegional: false,
      regionalParts: [],
      regionalFilter: {
        id: '',
        fieldValue: '',
        options: [{
          text: '',
          value: ''
        }],
        fieldLabel: i18n.t('genios.preview.contents.filter')
      },
      initialFilter: {
        text: '',
        value: 'All'
      }
    }
  },
  created() {
    let requestPath = "/api/tableOfContents/" + this.sourceShortcut;
    if (typeof this.issueId !== 'undefined' && this.issueId)
        requestPath = requestPath + "/" + this.issueId;
    getTableOfContents(requestPath, this.useDate).then((data) => {
      this.setupData(data);
    });
  },
  updated() {
    this.$nextTick(() => {
      updatePrices(this.$el);
    });
  },
  computed: {
    options() {
      const options = this.regionalParts.map(
          (item) => {
            return {
              text: item,
              value: item
            }
          });
      options.unshift(this.initialFilter);
      return options.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        } else if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    }
  },
  methods: {
    setupData(data) {
      this.hasPersonalizationPermission = data.hasPersonalizationPermission || false;
      this.latestArticles = data.latestArticles || [];
      this.filteredArticles = this.latestArticles || [];
      this.dbShortcut = data.dbShortcut || '';
      this.sourceName = data.sourceName || '';
      this.latestIssueDate = data.latestIssueDate;
      this.hasRegional = data.hasRegional || false;
      this.regionalParts = data.regionalParts || [];
      this.regionalFilter.options = this.options
      this.updateSearchResultSharedState(this.latestArticles.map((article) => {
        return {database: article.database, documentId: article.documentId, infoPrice: article.infoPrice, itemId: article.itemId, legacyDocId: article.legacyDocId, paid: article.paid, priceCategory: article.priceCategory}
      }));
    },
    updateShownArticles(event) {
      if (event.value === "All") {
        this.filteredArticles = this.latestArticles;
      } else {
        this.filteredArticles = this.latestArticles.filter((article) => article.regional === event.text);
      }
    },
    openPressNotification({currentTarget}) {
      const self = this;
      if (hasPermission("use_monitoring")) {
        self.openPressNotificationForm(currentTarget)
      } else {
        // if we close full popup modal, then the document popup also will be closed, this is not needed.
        // but this is not nice that we have "press notification" popup over the "document popup" (popup over popup)
        // closeFullPopupModal(false, false);
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='web_user_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#web_user_login", "genios.login.reasons.webUserLogin", function () {
          this.$nextTick(()=>{
            self.openPressNotificationForm(currentTarget)
          });
        });
      }
    },
    openPressNotificationForm(currentTarget) {
      openTransparentPopupModal();
      const overlayContent = document.querySelector("#overlay_content");
      overlayContent.innerHTML = '<div id="pnf"></div>';
      setupPressNotificationComponent("#pnf", currentTarget);
    },
    async openArticleOnClick(event, documentId) {
      const classList = event?.currentTarget?.querySelector('.tooltip')?.classList;
      if (!classList.contains('tooltip-active')) {
        if (sharedState.page !== 'document') {
          event.preventDefault();

          const forceConfirmPrice = event.currentTarget.hasAttribute("data-force-confirm-price");
          const linkHref = event.currentTarget.href;

          const {data} = await axios.post(`/api/retrieveDocuments`, {
            ids: [documentId],
            forceConfirmPrice: !!forceConfirmPrice,
          });

          if (data?.code) {
            await setupPaymentFormComponent(data, documentId, PaymentMode.fullDocument);
          } else {
            fakeVisitedLink({current_url: window.location.href, desired_url: linkHref})
            window.sharedState.fullArticleModalList = this.filteredArticles.map((article) => article.documentId);
            openFullArticleModal(documentId, true, forceConfirmPrice).then(() => {
            });
          }
          this.$nextTick(() => {
            updatePrices(document.body, documentId);
          });
        }
      } else {
        event.preventDefault();
      }
    },
    removeHighlightingAndConvertSpecialCharacters(elem) {
      return elem.replaceAll(/<em=".*">/g, "").replaceAll("</em>", "").replaceAll(/[^a-zA-Z0-9./äÄüÜöÖß-]/g, "_");
    },
    updateSearchResultSharedState(searchResult){
      if(!searchResult || searchResult.length === 0)
        return;

      if (!window.sharedState.searchResult)
        window.sharedState.searchResult = searchResult;
      else
        window.sharedState.searchResult =  window.sharedState.searchResult
            .filter(item => !searchResult.some(d=>d.documentId === item.documentId))
            .concat(searchResult);
    }
  }
}
</script>

<style scoped>

</style>