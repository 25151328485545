<template>
  <div class="registration__container">
    <div class="registration__left-panel">
      <h2 class="registration__left-panel__title">{{ $t('webUserRegistration.leftInfoPanel.title') }}</h2>
      <p class="registration__left-panel__subtitle">{{ $t('webUserRegistration.leftInfoPanel.listTitle') }}</p>
      <ul class="registration__left-panel__list">
        <li v-for="(infoItem) in leftPanelInfo" :key="infoItem.id" class="registration__left-panel__list-item">
          <ion-icon :name="infoItem.icon"></ion-icon>
          <span class="label">{{ $t("webUserRegistration.leftInfoPanel.item_" + (infoItem.id)) }}</span>
        </li>
      </ul>
    </div>
    <form @submit.prevent="submitForm" class="registration__right-panel">
      <h2 class="registration__right-panel__title">{{ $t('webUserRegistration.rightInfoPanel.title') }}</h2>
      <div class="registration__form">
        <RadioGroupComponent
            :group="salutationRadioBtns"
            :initial-value="''"
            direction="row"
            @update="updateSalutation"
        />

        <div class="registration__form__username">
          <TextFormElement
              max="30"
              :passed-value="firstName.value"
              :element="firstName"
              :focus="focus"
              @focus="focus = $event"
              @modified="firstName.value = $event"
          />
          <TextFormElement
              :passed-value="surname.value"
              :element="surname"
              :focus="focus"
              @focus="focus = $event"
              @modified="surname.value = $event"
          />
        </div>
        <div class="registration__form__registration-data">
          <TextFormElement
              :passedValue="email.value"
              :focus="focus"
              :element="email"
              :error="email.error !== ''"
              @modified="validateEmail"
              @focus="focus = $event"
          />
          <Error v-show="email.error" :error="email.error"/>

          <PasswordCriteriaComponent :criteria="passwordCriteria" />
          <TextFormElement
              :passedValue="password.value"
              :focus="focus"
              :element="password"
              :htmlInputFieldType="'password'"
              :error="password.error !== ''"
              @modified="validatePassword"
              @focus="focus = $event"
          />
          <Error v-show="password.error" :error="password.error"/>
          <TextFormElement
              :passedValue="confirmPassword.value"
              :focus="focus"
              :element="confirmPassword"
              :htmlInputFieldType="'password'"
              :error="confirmPassword.error !== ''"
              @modified="validateConfirmPassword"
              @focus="focus = $event"
          />
          <Error v-show="confirmPassword.error" :error="confirmPassword.error"/>
        </div>

      </div>

      <div class="registration__agreement">
        <CheckboxFormElement
            :element="termsAndConditions"
            :error="(!!termsAndConditions.error)"
            :passed-value="termsAndConditions.value"
            v-on:modified="termsAndConditions.value = $event"
        />
        <div class="enter__error">{{ termsAndConditions.error }}</div>

        <CheckboxFormElement
            :element="privacyPolicy"
            :error="(!!privacyPolicy.error)"
            :passed-value="privacyPolicy.value"
            v-on:modified="privacyPolicy.value = $event"
        />
        <div class="enter__error">{{ privacyPolicy.error }}</div>

        <p class="registration__form__mandatory">
          {{ $t('webUserRegistration.mandatory') }}
        </p>

      </div>
      <div class="registration__submit-error">
        <Error v-show="this.submitError" :error="this.submitError"/>
      </div>
      <div class="registration__submitBtn action-buttons">
        <Button
            type="button"
            @click="closeWebUserRegistration"
            :text="$t('genios.login.labels.cancel')"
            variant="secondary"
            :toggle-text="false"
        />
        <Button
          name="register"
          type="submit"
          :variant="formIsValid ? 'primary' : 'secondary'"
          :disabled="!formIsValid"
          :loading="isLoading"
          :text="$t('webUserRegistration.submitBtn')"
        />
    </div>
    </form>

    <InfoOverlayComponent
        v-if="showOverlay"
        :modal-data="modalData"
        @primary-action="handleOverlayPrimaryAction"
        @secondary-action="handleOverlaySecondaryAction"
    />
  </div>
</template>

<script>
import Error from "../styled/Error.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import i18 from "../plugins/Translations.vue";
import i18n from "../plugins/Translations.vue";
import Button from "../styled/Button.vue";
import ScheduleSubForm from "../Monitoring/ScheduleSubForm.vue";
import ActiveFilters from "../Filters/ActiveFilters.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import loginMixin from "../../../functions/mixins/loginMixin";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import RadioGroupComponent from "../FormElements/radio/RadioGroupComponent.vue";
import axios from "axios";
import PasswordCriteriaComponent from "../common/PasswordCriteriaComponent.vue";
import {passwordValidationCriteria} from "../../../functions/consts";
import StandardPopupComponent from "../styled/StandardPopupComponent.vue";
import EmailGroupComponent from "../Monitoring/EmailGroupComponent.vue";
import InfoOverlayComponent from "../common/InfoOverlayComponent.vue";

export default {
  name: "WebUserRegistrationForm",
  mixins: [loginMixin],
  components: {
    InfoOverlayComponent,
    EmailGroupComponent,
    StandardPopupComponent,
    PasswordCriteriaComponent,
    RadioGroupComponent,
    DropdownFormElement,
    CheckboxFormElement,
    ActiveFilters,
    ScheduleSubForm,
    Button,
    TextFormElement,
    Error
  },
  data() {
    return {
      focus: null,
      isLoading: false,
      popupData: {
        displayInfoPopup: false,
        content: '',
        title: '',
        hasNoError: true,
      },
      leftPanelInfo: null,
      salutation: {
        value: ''
      },
      firstName: {
        id: 'firstName',
        fieldLabel: i18.t('webUserRegistration.rightInfoPanel.firstName'),
        fieldType: 'text',
        value: '',
        error: false
      },
      surname: {
        id: 'surname',
        fieldLabel: i18.t('webUserRegistration.rightInfoPanel.surname'),
        fieldType: 'text',
        value: '',
        error: false
      },
      email: {
        id: 'email',
        fieldLabel: i18.t('webUserRegistration.rightInfoPanel.email'),
        fieldType: 'text',
        value: '',
        error: ''
      },
      password: {
        id: 'password',
        fieldLabel: i18.t('webUserRegistration.rightInfoPanel.password'),
        fieldType: 'text',
        value: '',
        error: ''
      },
      confirmPassword: {
        id: 'confirmPassword',
        fieldLabel: i18.t('webUserRegistration.rightInfoPanel.confirmPassword'),
        fieldType: 'text',
        value: '',
        error: ''
      },
      submitError: '',
      salutationRadioBtns: i18.t('orderingProcess.mainForm.paymentMethod.form.salutation.options').map(item => {
        return {
          id: `radio_${item.value}`,
          fieldLabel: item.text,
          value: item.value
        }
      }),
      showOverlay: false,
      modalData: null,
    }
  },
  computed: {
    userProfile: function () {
      return {
        salutation: this.salutation?.value || '',
        firstName: this.firstName?.value || '',
        lastName: this.surname?.value || '',
        email: this.email.value,
        password: this.password.value,
        acceptAgb: this.termsAndConditions.value,
        acceptGdpr: this.privacyPolicy.value
      }
    },
    emailValid() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(this.email.value);
    },
    passwordCriteria() {
      return passwordValidationCriteria(this.password.value)
    },
    passwordValid() {
      return this.passwordCriteria.map((criteria) => ({ valid: criteria.validation() }))
          .every(item => item.valid);
    },
    confirmPasswordValid() {
      return this.password.value === this.confirmPassword.value;
    },
    formIsValid() {
      return (
          this.emailValid &&
          this.passwordValid &&
          this.confirmPasswordValid &&
          this.termsAndConditions.value &&
          this.privacyPolicy.value
      );
    },
  },
  created() {
    this.leftPanelInfo = [
      { id: 1, icon: 'alarm-outline' },
      { id: 2, icon: 'bookmarks-outline' },
      { id: 3, icon: 'server-outline' },
      { id: 4, icon: 'documents-outline' },
      { id: 5, icon: 'stats-chart-outline' }
    ];
  },
  methods: {
    updateSalutation(event) {
      this.salutation.value = event;
    },
    validateEmail(event) {
      this.email.value = event
      this.email.error = '';
      if (!this.email.value) {
        this.email.error = i18n.t('webUserRegistration.emailValidation.emptyEmail');
      } else if (!this.emailValid) {
        this.email.error = i18n.t('webUserRegistration.emailValidation.invalid');
      }
    },
    validatePassword(event) {
      this.password.value = event;
      if (!this.password.value) {
        this.password.error = i18n.t('passwordValidation.emptyPassword');
      } else if (!this.passwordValid) {
        this.password.error = i18n.t('passwordValidation.invalid');
      } else {
        this.password.error = '';
        this.validateConfirmPassword();
      }
    },
    validateConfirmPassword(event) {
      this.confirmPassword.error = '';
      if (event) {
        this.confirmPassword.value = event;
      }
      if (!this.confirmPassword.value) {
        this.confirmPassword.error = i18n.t('webUserRegistration.confirmPasswordValidation.emptyPassword');
      } else if (!this.confirmPasswordValid) {
        this.confirmPassword.error = i18n.t('webUserRegistration.confirmPasswordValidation.notMatching');
      }
    },
    async submitForm() {
      this.showOverlay = false;
      this.submitError = '';
      this.isLoading = true;

      if (this.formIsValid) {
        try {
          const response = await axios.post("/api/registerWebUser", this.userProfile);

          if (response.data) {
            this.isLoading = false;

            if (!response.data.ok) {
              const errorKey = response.data.responseCode || 'connection';
              this.submitError = i18n.t('webUserRegistration.formValidation.error.' + errorKey);
            } else {
              let description = i18n.t('webUserRegistration.formValidation.registrationResponse.success');
              let title = i18n.t('webUserRegistration.formValidation.registrationResponse.title');

              switch (response.data.value) {
                case 'ERROR':
                  description = i18n.t('webUserRegistration.formValidation.registrationResponse.registrationNotCompleted');
                  break;
                case 'ALREADY_EXISTS':
                  description = i18n.t('webUserRegistration.formValidation.registrationResponse.emailAlreadyUsed');
                  break;
                case 'NUMBER_OF_WEB_USERS_EXCEEDED':
                  description = i18n.t('webUserRegistration.formValidation.registrationResponse.numberOfWebUsersExceeded');
                  break;
                case 'NEEDS_CONFIRMATION':
                  description = i18n.t('webUserRegistration.formValidation.registrationResponse.needsConfirmation');
                  break;
                case 'SUCCESSFUL':
                  description = i18n.t('webUserRegistration.formValidation.registrationResponse.successful');
                  break;
              }

              this.modalData = {
                title: title,
                description: description,
                primaryButtonText: i18n.t('webUserRegistration.formValidation.registrationResponse.primaryButton'),
              };

              this.showOverlay = true;
            }
          }
        } catch (error) {
          this.isLoading = false;
          this.submitError = i18n.t('webUserRegistration.formValidation.error.connection');
          console.error(error);
        }
      } else {
        this.submitError = i18n.t('webUserRegistration.formValidation.error.invalidInput');
        this.isLoading = false;
      }
    },
    handleOverlayPrimaryAction() {
      this.showOverlay = false;
      window.location.replace("/");
    },
    handleOverlaySecondaryAction() {
      this.showOverlay = false;
      window.location.replace("/");
    },
    closeWebUserRegistration() {
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
.enter__error {
  color: #CC1D33;
  font-size: 0.9em;
}

.registration__form__mandatory {
  color: var(--shade-dark);
}

.action-buttons {
  gap: 10px
}
</style>