<template>
  <div class="header-layout header-action-bar">
    <select-all :focus="focus" :is-all-selected="actionBarConfig.isAllSelected" @update:selected-all="$emit('update:selected-all', $event)"></select-all>
    <action-bar-layout
        :action-bar-buttons="actionBarConfig.dropdownActionBarButtons"
        :selected-articles="actionBarConfig.selectedArticles"
        :all-documents-on-page="actionBarConfig.allDocumentsOnPage"
        @remove-all="$emit('remove-all')"
        @update-prices="$emit('update-prices', $event)"
        @update-list="$emit('update-list')"
    ></action-bar-layout>
    <template v-if="actionBarConfig.shouldDisplaySortBar">
      <settings-bar-component :settings='actionBarConfig.settingsBar' @updated:filter-and-sort="$emit('updated:filter-and-sort', $event)"></settings-bar-component>
    </template>
  </div>
</template>

<script>
import SelectAll from "./SelectAll.vue";
import DropdownFormElement from "../../FormElements/DropdownFormElement.vue";
import ActionBarLayout from "./ActionBarLayout.vue";
import {setupScreenWidthObserver} from "../../../../functions/stylization";
import SortBar from "./SortBar.vue";
import SettingsBarComponent from "./SettigsBarComponent.vue";

export default {
  name: "HeaderLayout",
  components: {SettingsBarComponent, SortBar, ActionBarLayout, DropdownFormElement, SelectAll},
  data() {
    return {
      focus: null,
    }
  },
  props: {
    actionBarConfig: {
      dropdownActionBarButtons: {
        type: Array,
        required: true,
        default: []
      },
      selectedArticles: {
        type: Array,
        required: false,
        default: []
      },
      allDocumentsOnPage: {
        type: Array,
        required: false,
        default: []
      },
      shouldDisplaySortBar: {
        type: Boolean,
        required: false
      },
      settingsBar: {
        type: Object,
        required: false,
      }
    }
  },
  mounted() {
    setupScreenWidthObserver();
  }
}
</script>