<template>
  <mein-genios-template :pageTitle="$t('meinGenios.basicSettings.title')" :page-info="$t('meinGenios.basicSettings.info')">
    <template v-slot:content>
        <div
            v-for="(group, groupIndex) in groupedSettings"
            :key="'group-' + groupIndex"
            class="basic-settings"
        >
          <div class="basic-settings__group--title" v-if="group.title">{{ $t(group.title) }}</div>
          <div class="basic-settings__group--subtitle" v-if="group.subtitle">{{ $t(group.subtitle) }}</div>
          <div :class="`basic-settings__group flex-${group.display}`">
            <div
                v-for="(setting, settingIndex) in group.settings"
                :key="'setting-' + groupIndex + '-' + settingIndex"
            >
              <div class="basic-settings__group--subtitle" v-if="setting.subtitle">{{ $t(setting.subtitle) }}</div>
              <Toggle
                  v-if="setting.type === 'toggle'"
                  class="basic-settings__toggle"
                  :isSelected="settingsValues[`${groupIndex}-${settingIndex}`]"
                  :id="setting.id"
                  :label="$t(setting.label)"
                  @update-toggle="updateToggle(groupIndex, settingIndex, $event)"
              />
              <sort-bar
                  v-else-if="setting.type === 'dropdown'"
                  :sorting-elements="{
                    id: setting.id,
                    fieldLabel: $t(setting.label),
                    options: setting.options,
                    defaultSelected: settingsValues[`${groupIndex}-${settingIndex}`]
                  }"
                  @updated:filter-and-sort="updateDropdown(groupIndex, settingIndex, $event)"
              />
              <div v-else-if="setting.type === 'link'" class="simulate-link link-color" @click.stop="navigateToLink(setting.link)">
                {{ $t(setting.label) }}
              </div>
            </div>
          </div>
        </div>
        <Button
            variant="primary"
            name="saveAll"
            border="none"
            aria-label="save all settings"
            :text="$t('meinGenios.basicSettings.saveAllSettings')"
            :toggle-text="false"
            class="basic-settings__save-btn"
            type="button"
            position="left"
            @click="saveAllSettings"
        />
      <InfoOverlayComponent
          v-if="showConfirmationModal"
          :modal-data="modalContent"
          @primary-action="closeInfoOverlay"
          @secondary-action="closeInfoOverlay"
      />
    </template>
  </mein-genios-template>
</template>

<script>
import SortBar from "./common/SortBar.vue";
import Toggle from "../styled/Toggle.vue";
import Button from "../styled/Button.vue";
import {BookmarksSortAndOrder, SearchResultSortAndOrder} from "../../../functions/consts";
import MeinGeniosTemplate from "./MeinGeniosTemplate.vue";
import InfoOverlayComponent from "../common/InfoOverlayComponent.vue";
import {hasPermission} from "../../../functions/utils/permission_utils";
import {closeFullPopupModal, closeTransparentPopupModal} from "../../../functions/closing";
import {setupLoginComponent} from "../../../functions/setups/vue-components";

export default {
  components: {InfoOverlayComponent, SortBar, Toggle, Button, MeinGeniosTemplate, },
  data() {
    return {
      showConfirmationModal: false,
      modalContent: {},
      settingsValues: {},
      groupedSettings: [
        {
          title: 'meinGenios.basicSettings.searchFilterDisplaySettings.title',
          subtitle: 'meinGenios.basicSettings.searchFilterDisplaySettings.subtitle',
          display: 'column',
          settings: [
            {
              id: 'categoryFilterOpened',
              type: 'toggle',
              label: 'meinGenios.basicSettings.searchFilterDisplaySettings.category',
              value: true,
            },
            {
              id: 'advancedSearchOpened',
              type: 'toggle',
              label: 'meinGenios.basicSettings.searchFilterDisplaySettings.advancedSearch',
              value: false,
            },
            {
              id: 'filtersOpened',
              type: 'toggle',
              label: 'meinGenios.basicSettings.searchFilterDisplaySettings.filter',
              value: true,
            },
          ],
          saveAction: 'saveAdvancedSearchSettings',
          getter: 'getSearchFilterDisplaySettings',
        },
        {
          display: 'row',
          settings: [
            {
              id: 'useDecomposition',
              subtitle: 'meinGenios.basicSettings.searchResultSettings.useDecomposition.subtitle',
              type: 'toggle',
              label: 'meinGenios.basicSettings.searchResultSettings.useDecomposition.label',
            },
          ],
          saveAction: 'saveSearchResultSettings',
          getter: 'getSearchResultSettings',
        },
        {
          title: 'meinGenios.basicSettings.searchResultSettings.title',
          display: 'row',
          settings: [
            {
              id: 'view',
              type: 'dropdown',
              label: 'meinGenios.basicSettings.searchResultSettings.view.label',
              subtitle: 'meinGenios.basicSettings.searchResultSettings.view.subtitle',
              value: 'list',
              options: [{
                text: this.$t('meinGenios.basicSettings.searchResultSettings.view.fullView'),
                value: 'list'
              }, {
                text: this.$t('meinGenios.basicSettings.searchResultSettings.view.gridView'),
                value: 'grid'
              }
              ],
            },
            {
              id: 'sortAndOrder',
              type: 'dropdown',
              label: 'meinGenios.basicSettings.searchResultSettings.sortAndOrder.label',
              subtitle: 'meinGenios.basicSettings.searchResultSettings.sortAndOrder.subtitle',
              value: SearchResultSortAndOrder[0],
              options: SearchResultSortAndOrder.map((item) => ({
                ...item,
                text: this.$t(item.text),
              })),
            },
            {
              id: 'size',
              type: 'dropdown',
              label: 'meinGenios.basicSettings.searchResultSettings.size.label',
              subtitle: 'meinGenios.basicSettings.searchResultSettings.size.subtitle',
              value: 10,
              options: [{
                text: '10',
                value: 10
              }, {
                text: '25',
                value: 25
              },
              {
                text: '50',
                value: 50
              }],
            },
          ],
          saveAction: 'saveSearchResultSettings',
          getter: 'getSearchResultSettings',
        },
        {
          title: 'meinGenios.basicSettings.bookmarksResultSettings.title',
          display: 'row',
          settings: [
            {
              id: 'sortAndOrder',
              type: 'dropdown',
              label: 'meinGenios.basicSettings.searchResultSettings.sortAndOrder.label',
              subtitle: 'meinGenios.basicSettings.searchResultSettings.sortAndOrder.subtitle',
              value: BookmarksSortAndOrder[0],
              options: BookmarksSortAndOrder.map((item) => ({
                ...item,
                text: this.$t(item.text),
              })),
            },
            {
              id: 'size',
              type: 'dropdown',
              label: 'meinGenios.basicSettings.searchResultSettings.size.label',
              subtitle: 'meinGenios.basicSettings.searchResultSettings.size.subtitle',
              value: 10,
              options: [{
                text: '10',
                value: 10
              }, {
                text: '25',
                value: 25
              },
                {
                  text: '50',
                  value: 50
                }],
            },
          ],
          saveAction: 'saveBookmarksResultSettings',
          getter: 'getBookmarksResultSettings',
        },
        {
          title: 'meinGenios.basicSettings.otherSettings.title',
          display: 'column',
          settings: [
            {
              id: 'otherSettings',
              type: 'link',
              label: 'meinGenios.basicSettings.otherSettings.label',
              link: '/settings/show?tab=misc&src=refresh&redirectUrl=%2F'
            }
          ],
        },
      ],
    };
  },
  mounted() {
    this.groupedSettings.forEach((group, groupIndex) => {
      group.settings.forEach((setting, settingIndex) => {
        const initialValue = this.getInitialValue(groupIndex, settingIndex);
        this.$set(this.settingsValues, `${groupIndex}-${settingIndex}`, initialValue);
      });
    });
  },
  methods: {
    getInitialValue(groupIndex, settingIndex) {
      const group = this.groupedSettings[groupIndex];
      const setting = group.settings[settingIndex];

      if (group.getter) {
        const storeValue = this.$store.getters[group.getter]?.[setting.id];

        if (storeValue !== undefined) {
          this.$set(this.settingsValues, `${groupIndex}-${settingIndex}`, storeValue);
          this.$set(this.groupedSettings[groupIndex].settings, settingIndex, {
            ...setting,
            value: storeValue,
          });
          return storeValue;
        }
      }
      this.$set(this.settingsValues, `${groupIndex}-${settingIndex}`, setting.value);
      this.$set(this.groupedSettings[groupIndex].settings, settingIndex, {
        ...setting,
        value: setting.value,
      });
      return setting.value;
    },

    updateToggle(groupIndex, settingIndex, value) {
      this.updateSetting(groupIndex, settingIndex, {value});
    },

    updateDropdown(groupIndex, settingIndex, value) {
      this.updateSetting(groupIndex, settingIndex, {value});
    },

    updateSetting(groupIndex, settingIndex, newSettings) {
      const setting = this.groupedSettings[groupIndex].settings[settingIndex];

      this.groupedSettings[groupIndex].settings[settingIndex] = {
        ...setting,
        ...newSettings,
      };
    },

    saveAllSettings() {
      if (hasPermission("can_save_general_settings")) {
        this.saveAll();
      } else {
        const self = this;
        closeFullPopupModal(false, false);
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='web_user_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#web_user_login", "genios.login.reasons.webUserLogin", function () {
          closeTransparentPopupModal();
          self.saveAll();
        });
      }
    },

    async saveAll() {
      const groupedActions = {};

      this.groupedSettings.forEach((group) => {
        if (group.saveAction) {
          if (!groupedActions[group.saveAction]) {
            groupedActions[group.saveAction] = {};
          }
          group.settings.forEach((setting) => {
            groupedActions[group.saveAction][setting.id] = setting.value;
          });
        }
      });

      Object.keys(groupedActions).forEach((action) => {
        if (action) {
          this.$store.dispatch(action, groupedActions[action]);
        }
      });
      this.modalContent = {
        title: this.$t(`meinGenios.basicSettings.saveSettingsModal.title`),
        description: this.$t(`meinGenios.basicSettings.saveSettingsModal.description`),
        primaryButtonText: this.$t(`meinGenios.basicSettings.saveSettingsModal.confirm`),
      };
      this.showConfirmationModal = true;
    },

    closeInfoOverlay() {
      this.showConfirmationModal = false;
    },

    navigateToLink(link) {
      window.location.href = link;
    },
  }
};
</script>

<style scoped>
</style>
