<template>
  <fragment>
    <h3 class="email_group__title">{{ $t("genios.monitoring.emailGroup.title") }}</h3>
    <spinner v-if="isLoading"/>
    <p class="email_group__message" v-else-if="element.options.length <= 0">
      {{ $t("genios.monitoring.emailGroup.emptyList") }}
    </p>
    <div class="email_group__data" v-else>
      <checkbox-form-element
          v-for="option in renderedList"
          :key="option.emailGroupId"
          :element="option"
          :passed-value="option.fieldValue"
          :focus="focus"
          @focus="focus = $event"
          :class="option.fieldValue ? 'checked': ''"
          @modified="updateCheckboxFields($event, option)"
      />
      <dropdown-form-element
          v-if="dropdownList.length > 0"
          :element="{...element, options: dropdownList}"
          :focus="focus"
          @focus="focus = $event"
          @modified="updateDropdownFields($event)"
          :passedValue="{text: $t('genios.monitoring.emailGroup.dropdownTitle'), value: 'title'}"
      />
    </div>
    <div class="email_group__actions">
      <Button
          :text="$t('genios.closeWindow')"
          variant="secondary"
          type="button"
          :toggle-text="false"
          @click="closePopup"
      />
      <Button
          :text="$t('genios.monitoring.emailGroup.submitButton')"
          variant="primary"
          type="button"
          :disabled="isLoading"
          :toggle-text="false"
          @click="saveEmailsGroup"
      />
    </div>
  </fragment>
</template>

<script>
import axios from "axios";
import _ from "lodash"

import Button from "../styled/Button.vue";
import Spinner from "../styled/Spinner.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import eventBus from "../../../eventBus";

export default {
  name: "EmailGroupComponent",
  components: {
    CheckboxFormElement,
    DropdownFormElement,
    Button,
    Spinner
  },
  props: {
    checkedEmailsGroup: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      focus: null,
      currentSeparateIndex: 3,
      element: {
        options: []
      },
      renderedStashedList: [],//to restore data if not saved
      dropdownStashedList: [], //to restore data if not saved
      renderedList: [],
      dropdownList: [],
      prefix: "element_"
    }
  },
  async mounted() {
    await this.fetchUserEmailGroups()
    await this.$nextTick(() => {
      this.renderedStashedList = _.cloneDeep(this.renderedList)
      this.dropdownStashedList = _.cloneDeep(this.dropdownList)
    })
  },
  computed: {
    emailsToMonitoring() {
      return this.renderedList.map(item => {
        return {
          id: `${item.emailGroupId}__${item.id}`,
          text: item.name,
          fieldLabel: item.name,
          fieldValue: item.fieldValue,
          value: item.value,
          name: item.name,
          field: `${item.emailGroupId}__${item.id}`,
          emails: item.emails,
          emailGroupId: item.emailGroupId
        }
      }).filter(item => item.fieldValue).map(item => {
        return {
          ...item,
          emails: item.emails.map(item => item.email)
        }
      })
    }
  },
  methods: {
    closePopup() {
      this.renderedList = _.cloneDeep(this.renderedStashedList)
      this.dropdownList = _.cloneDeep(this.dropdownStashedList)
      this.$emit("close-popup")
    },
    updateDropdownFields(event) {
      const index = this.element.options.findIndex(item => item.id === event.id)

      this.element.options[index] = {
        ...this.element.options[index],
        value: !this.element.options[index].value,
        fieldValue: !this.element.options[index].fieldValue
      }

      this.renderedList.push({...this.element.options[index], value: true, fieldValue: true})

      const indexToRemove = this.dropdownList.findIndex(item => item.id === event.id)
      this.dropdownList.splice(indexToRemove, 1)
    },
    updateCheckboxFields(event, option) {
      option.fieldValue = event
      option.value = event
    },
    async fetchUserEmailGroups() {
      try {
        const {data} = await axios.get('/api/emailGroups')
        this.element = {
          options: data.map((item, index) => {
            return {
              ...this.generateObj(item, index)
            }
          })
        }
        this.renderedList = this.element.options.filter((_, index) => index < this.currentSeparateIndex)
        this.dropdownList = this.element.options.filter((_, index) => index >= this.currentSeparateIndex)
      } catch (error) {
        console.log("fetchUserEmailGroups:error => ", error)
        console.dir(error)
      } finally {
        this.isLoading = false
      }

    },
    saveEmailsGroup() {
      eventBus.$emit("update-group-list", this.emailsToMonitoring)
      this.$emit('close-popup')
      this.renderedStashedList = _.cloneDeep(this.renderedList)
      this.dropdownStashedList = _.cloneDeep(this.dropdownList)
    },
    generateObj(item, index) {
      return {
        id: `${this.prefix}${index}`,
        text: item.name,
        fieldLabel: item.name,
        fieldValue: false,
        value: false,
        name: item.name,
        field: item.name,
        emails: item.emails,
        emailGroupId: item.emailGroupId
      }
    }
  },
  watch: {
    checkedEmailsGroup(newArr) {
      const diffArr = _.differenceBy(this.emailsToMonitoring, newArr, item => item.id)

      if (diffArr.length === 1) {
        const changedOption = diffArr[0]
        const index = this.renderedList.findIndex(item => item.emailGroupId === changedOption.emailGroupId)

        this.$set(this.renderedList, index, {
          ...this.renderedList[index],
          value: false,
          fieldValue: false
        })

        this.renderedStashedList = _.cloneDeep(this.renderedList)
      }
    }
  }
}
</script>