export const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export const convertToZulu = (dateString) => {
    if (/^\d{4}$/.test(dateString)) {
        const year = Number(dateString);
        const date = new Date(Date.UTC(year, 0, 1));
        return date.toISOString();
    }

    const [day, month, year] = dateString.split('.').map(Number);

    if (!day || !month || !year) {
        return '';
    }

    const date = new Date(Date.UTC(year, month - 1, day));
    return date.toISOString();
};