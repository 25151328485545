import {fetchBookmarksFromApi, updateDocumentPrices} from "../../fetching";


const initialState = {
    bookmarks: [],
    selectedBookmarks: [],
    totalNumberOfBookmarks: 0,
    currentPage: 1,
    loading: true,
    error: false,
    errorMessage: "",
    ...window.sessionVariables?.['vuexState']?.bookmarksModule || {}
}

const bookmarksModule =  {
    namespaced: true,
    state: {...initialState},
    getters: {
        getBookmarks: state => state.bookmarks,
        getSelectedBookmarks: state => state.selectedBookmarks,
        getTotalNumberOfBookmarks: state => state.totalNumberOfBookmarks,
        getCurrentPage: state => state.currentPage,
        getLoading: state => state.loading,
    },
    mutations: {
        setBookmarks(state, bookmarks) {
            state.bookmarks = bookmarks;
        },
        setSelectedBookmarks(state, bookmarks) {
            state.selectedBookmarks = bookmarks.filter((bookmark, index, self) =>
                index === self.findIndex(b => b.bookmarkId === bookmark.bookmarkId)
            );
        },
        setTotalNumberOfBookmarks(state, noOfBookmarks) {
            state.totalNumberOfBookmarks = noOfBookmarks;
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload
        },
    },
    actions: {
        async fetchBookmarks({ commit, dispatch, rootGetters, getters }, bookmarkListId) {
            commit('setLoading', true);
            let params = {
                id: bookmarkListId,
                offset: getters.getCurrentPage - 1,
                size: rootGetters.getUserInteractionBookmarksResultSettings.size,
                sort: rootGetters.getUserInteractionBookmarksResultSettings.sortAndOrder.sort,
                order: rootGetters.getUserInteractionBookmarksResultSettings.sortAndOrder.order,
            };
            try {
                commit('setBookmarks', []);
                let bookmarksForBookmarkList = await fetchBookmarksFromApi(params);
                if (!bookmarksForBookmarkList || Object.keys(bookmarksForBookmarkList).length === 0) {
                    commit('setCurrentPage', 1);
                    params = { ...params, offset: 0}
                    bookmarksForBookmarkList = await fetchBookmarksFromApi(params);
                }
                commit('setTotalNumberOfBookmarks', bookmarksForBookmarkList?.totalPages || 0);
                const items = bookmarksForBookmarkList?.content || [];

                const allBookmarkDocumentIds = items.map(item => item.bookmarkedItem);
                const docIds = [...allBookmarkDocumentIds];
                const bookmarks = await updateDocumentPrices(docIds, items);
                commit('setBookmarks', bookmarks || []);
            } catch (e) {
                commit('setBookmarks', []);
                commit('setTotalNumberOfBookmarks', 0);
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async createBookmarkList({ commit }, bookmarkList) {
            commit('setLoading', true);
            try {
                commit('setBookmarkList', bookmarkList);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
    },
}
export default bookmarksModule;