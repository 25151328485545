<template>
  <mein-genios-template :pageTitle="$t('meinGenios.documentHistory.title')" :page-info="$t('meinGenios.documentHistory.info')">
    <template v-slot:content>
      <header-layout
          v-if="documentHistoryArray.length > 0"
          :action-bar-config="actionBarConfig"
          @remove-all="removeAll"
          @update:selected-all="selectAll($event)"
          @updated:filter-and-sort="filterAndSort($event)"
          @update-prices="updatePrices($event)"
          @update-list="updateList"
      ></header-layout>
      <div class="list-view-wrapper" v-if="documentHistoryArray.length > 0">
        <div class="list-container list-view-switcher list-view">
          <ArticleElementComponent
              v-for="(item, index) in documentHistoryArray"
              :key="item.documentId + index"
              :document="item"
              :view="'listView'"
              :sort-by="sort"
              :isSelected="selectedDocumentHistory.map(doc => doc.documentId).includes(item.documentId)"
              :index="index"
              :action-bar-config="actionBarConfig"
              @update-selected="updateSelectedDocumentHistory"
              @update-prices="updatePrices($event)"
              @update-list="updateList"
          />
          <pagination-component
              v-if="pagesQuantity > 1"
              :pages="pagesQuantity"
              :selected="currentPage"
              @update-page="updateCurrentPage"
          />
        </div>
      </div>
      <div v-else>
        <empty-content-placeholder :icon="'document-outline'" :message="$t('meinGenios.documentHistory.empty')" :is-loading="isLoading"></empty-content-placeholder>
      </div>
    </template>
  </mein-genios-template>
</template>

<script>
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import ArticleElement from "../styled/ArticleElementComponent.vue";
import ArticleElementComponent from "../styled/ArticleElementComponent.vue";
import ActionBarComponent from "../ActionBar/ActionBarComponent.vue";
import PaginationComponent from "../styled/PaginationComponent.vue";
import MeinGeniosTemplate from "./MeinGeniosTemplate.vue";
import HeaderLayout from "./common/HeaderLayout.vue";
import {ActionBarType, DocumentHistorySortAndOrder} from "../../../functions/consts";
import documentHistoryModule from "../../../functions/store/modules/documentHistory";
import {updateDocumentPrices} from "../../../functions/fetching";
import EmptyContentPlaceholder from "../styled/EmptyContentPlaceholder.vue";
import {queryParamMixin} from "../../../functions/setups/vue-components/queryParamMixin";
import eventBus from "../../../eventBus";

export default {
  name: "DocumentHistoryComponent.vue",
  components: {
    EmptyContentPlaceholder,
    HeaderLayout,
    MeinGeniosTemplate,
    PaginationComponent,
    ActionBarComponent,
    ArticleElementComponent,
    ArticleElement,
    DropdownFormElement,
  },
  mixins: [queryParamMixin],
  data() {
    return {
      moduleName: 'documentHistoryModule',
      dropdownActionBarButtons: ActionBarType.DROPDOWN_DOCUMENT_HISTORY,
      actionBarButtons: ActionBarType.DOCUMENT_HISTORY,
    }
  },
  computed: {
    selectedDocumentHistory() {
      return this.$store.getters['documentHistoryModule/getSelectedDocumentHistory']
    },
    documentHistoryArray() {
      return this.$store.getters['documentHistoryModule/getDocumentHistory']?.map(item => ({
        ...item,
        isDocumentHistory: true,
      })) || [];
    },
    isAllSelected() {
      const storedIds = this.selectedDocumentHistory.map(doc => doc.documentId);
      return this.documentHistoryArray.length > 0 && this.documentHistoryArray.every(docHistory => storedIds.includes(docHistory.documentId));
    },
    currentPage() {
      return this.$store.getters['documentHistoryModule/getCurrentPage'];
    },
    size() {
      return this.$store.getters['documentHistoryModule/getSortSize'];
    },
    sort() {
      return this.$store.getters['documentHistoryModule/getSortBy'];
    },
    order() {
      return this.$store.getters['documentHistoryModule/getSortOrder'];
    },
    pagesQuantity() {
      return (this.$store.getters['documentHistoryModule/getPagesForRequestedDocuments'] || 0 )
    },
    isLoading() {
      return this.$store.getters['documentHistoryModule/getLoading']
    },
    actionBarConfig() {
      return {
        dropdownActionBarButtons: this.dropdownActionBarButtons,
        actionBarButtons: this.actionBarButtons,
        selectedArticles: this.selectedDocumentHistory,
        isAllSelected: this.isAllSelected,
        allDocumentsOnPage: this.documentHistoryArray,
        shouldDisplaySortBar: true,
        settingsBar: {
          sizeSelected: this.size,
          sortAndOrder: {
            selectedOption: {
              sort: this.sort,
              order: this.order,
            },
            optionsArray: DocumentHistorySortAndOrder
          }
        },
      }
    }
  },
  async created() {
    if (!this.$store.hasModule('documentHistoryModule')) {
      this.$store.registerModule('documentHistoryModule', documentHistoryModule);
    }
  },
  async mounted() {
    const query = this.getQueryParams();
    this.saveQueryParamsToStore(query);
    if (query) {
      await this.loadDocumentHistory();
    }
    this.updateRoute();

    eventBus.$on("documentsBookmarked", value => {
      if (value) {
        this.removeAll(false);
      }
    })
  },
  beforeDestroy() {
    eventBus.$off('documentsBookmarked');
  },
  methods: {
    async loadDocumentHistory() {
      await this.$store.dispatch('documentHistoryModule/fetchDocumentHistory');
    },
    async updateCurrentPage(page) {
      if (page !== this.currentPage) {
        this.$store.commit('documentHistoryModule/setCurrentPage', page);
        this.updateRoute();
      }
    },
    updateSelectedDocumentHistory({ documentId, selected }) {
      const selectedDocumentHistory = this.$store.getters['documentHistoryModule/getSelectedDocumentHistory'];
      const isDocumentSaved = selectedDocumentHistory.find(item => item.documentId === documentId);
      if (selected && isDocumentSaved || !selected && !isDocumentSaved) {
        return;
      }
      const newSelectedDocumentHistory = this.documentHistoryArray.filter(item => item.documentId === documentId) || [];
      if (selected && newSelectedDocumentHistory) {
        this.$store.commit('documentHistoryModule/setSelectedDocumentHistory', [...selectedDocumentHistory, ...newSelectedDocumentHistory]);
      } else {
        this.$store.commit('documentHistoryModule/setSelectedDocumentHistory', selectedDocumentHistory.filter(item => item.documentId !== documentId));
      }
    },
    removeAll(changePage = true) {
      this.$store.commit('documentHistoryModule/setSelectedDocumentHistory', []);
      if (changePage) {
        this.$store.commit('documentHistoryModule/setCurrentPage', 1);
      }
    },
    selectAll(event) {
      if (event) {
        this.$store.commit('documentHistoryModule/setSelectedDocumentHistory', [...this.selectedDocumentHistory, ...this.documentHistoryArray]);
      } else {
        const remainingSelectedDocuments = this.selectedDocumentHistory.filter(
            item1 => !this.documentHistoryArray.some(item2 => item1.documentId === item2.documentId)
        );
        this.$store.commit('documentHistoryModule/setSelectedDocumentHistory', remainingSelectedDocuments);
      }
    },
    async filterAndSort(event) {
      this.$store.commit('documentHistoryModule/setCurrentPage', 1);
      this.$store.commit('documentHistoryModule/setSortBy', event.sort);
      this.$store.commit('documentHistoryModule/setSortOrder', event.order);
      this.$store.commit('documentHistoryModule/setSortSize', event.size);
      this.updateRoute();
    },
    async updatePrices(ids) {
      const documentsToUpdate = this.documentHistoryArray.filter(item => ids.includes(item.documentId));
      const updatedDocuments = await updateDocumentPrices(ids, documentsToUpdate);
      const mergedDocuments = this.documentHistoryArray.map(doc => {
        const updatedDocument = updatedDocuments.find(b => b.documentId === doc.documentId);
        return updatedDocument ? {...doc, ...updatedDocument} : doc;
      });
      this.$store.commit('documentHistoryModule/setDocumentHistory', mergedDocuments);
    },
    async updateList() {
      const oldPage = this.currentPage;
      this.$store.commit('documentHistoryModule/setDocumentHistory', []);
      this.$store.commit('documentHistoryModule/setSelectedDocumentHistory', []);
      this.$store.commit('documentHistoryModule/setCurrentPage', 1);
      this.updateRoute();
      if (oldPage === 1) {
        await this.loadDocumentHistory();
      }
    },
    updateRoute() {
      this.syncQueryParams({
        page: this.currentPage,
        size: this.size,
        sort: this.sort,
        order: this.order
      });
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      async handler(query) {
        if (query && this.$store.hasModule(this.moduleName)) {
          this.saveQueryParamsToStore(query);
          await this.loadDocumentHistory();
        }
      },
    },
  },
}
</script>
